import axios from '../../helpers/axios';

const authService = {
  getUserProfile,
  validatePermissions,
  deleteToken,
};

function getUserProfile() {
  return axios
    .get('/auth/profile', { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function validatePermissions(permissions = []) {
  return axios
    .post(`/auth/token/validation`, { permissions }, { withCredentials: true })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteToken() {
  return axios
    .delete('/auth/token', null, {
      withPermissions: ['AUTH_USER_DELETE_TOKEN'],
      withCredentials: true,
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export default authService;
