import responsiveConstants from '../constants/responsive.constants';

/**
 * 1 - small device < 760px
 * 2 - medium device >= 760px && < 1280px
 * 3 - large device > 1280px
 * 4 - extra large device > 1600px
 */

const deviceSize = (windowWidth) => {
  const calculatedSize = (() => {
    switch (true) {
      case windowWidth < 760:
        return 1;
      case windowWidth >= 760 && windowWidth < 1280:
        return 2;
      case windowWidth >= 1280 && windowWidth < 1600:
        return 3;
      case windowWidth >= 1600:
        return 4;
      default:
        return 3;
    }
  })();
  return calculatedSize;
};

function responsive(state = {}, action) {
  switch (action.type) {
    case responsiveConstants.CHANGE_DEVICE_SIZE:
      return {
        deviceSize: deviceSize(action.windowWidth),
      };
    default:
      return {
        ...state,
      };
  }
}

export default responsive;
