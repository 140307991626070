import { Button, Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const AccountDeleted = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const redirectToLogin = () => {
    history.push(`/${i18n.language}`);
  };

  return (
    <Container className="d-flex flex-column h-100">
      <Row className="pl-3 pr-3">
        <Col>
          <Header noRedirect redirectType={'login'} />
        </Col>
      </Row>
      <Row className="m-auto col-lg-6 col-md-8 col-12 offset-lg-3 offset-md-2">
        <Col className="d-flex flex-column align-items-center">
          <img
            className={'account_deleted_image'}
            src="/images/account-deleted.svg"
            alt={'account deleted'}
          />
          <h1 className={'mb-4'}>{t('deletedAccount.title')}</h1>
          <span className={'account_deleted_description'}>{t('deletedAccount.description')}</span>
          <Button
            className="mt-7 w-100 btn-medium shadow-none"
            variant="primary"
            type="submit"
            onClick={redirectToLogin}
          >
            {t('deletedAccount.button')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountDeleted;
