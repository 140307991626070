import axios from '../../helpers/axios';
import parseError from '../../helpers/parseError';

const registerService = {
  register,
  reSendActivationEmail,
  getRegisterCodeStatus,
  setPhoneNumber,
  confirmPhoneNumber,
  reSendConfirmPhoneCode,
  setPassword,
};

function register(email, language, captchaToken) {
  return axios
    .post(
      `/auth/registration`,
      {
        email,
        language: language.toUpperCase(),
        agreements: ['BASIC_AGREEMENT'],
      },
      {
        headers: {
          Captcha: captchaToken,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function reSendActivationEmail(email) {
  return axios
    .get(`/auth/registration/email/${email}/resend`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function getRegisterCodeStatus(code) {
  return axios
    .get(`/auth/registration/codes/${code}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function setPhoneNumber(code, prefix, phoneNumber) {
  return axios
    .post(`/auth/registration/codes/${code}/phone`, {
      phoneNumber: {
        number: phoneNumber,
        prefix: `+${prefix}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || error));
}

function confirmPhoneNumber(activationCode, smsCode) {
  return axios
    .post(`/auth/registration/codes/${activationCode}/phone/confirm`, {
      confirmationCode: smsCode,
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function reSendConfirmPhoneCode(code) {
  return axios
    .post(`/auth/registration/codes/${code}/phone/resend`, {})
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function setPassword(code, password) {
  return axios
    .post(
      `/auth/registration/codes/${code}/password`,
      {
        password,
      },
      { withCredentials: true }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export default registerService;
