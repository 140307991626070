const passwordResetConstants = {
  REMIND_PASSWORD_REQUEST: 'REMIND_PASSWORD_REQUEST',
  REMIND_PASSWORD_SUCCESS: 'REMIND_PASSWORD_SUCCESS',
  REMIND_PASSWORD_FAILURE: 'REMIND_PASSWORD_FAILURE',

  CHECK_RESET_PASSWORD_TOKEN_REQUEST: 'CHECK_RESET_PASSWORD_TOKEN_REQUEST',
  CHECK_RESET_PASSWORD_TOKEN_SUCCESS: 'CHECK_RESET_PASSWORD_TOKEN_SUCCESS',
  CHECK_RESET_PASSWORD_TOKEN_FAILURE: 'CHECK_RESET_PASSWORD_TOKEN_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  RESET_STORE: 'RESET_STORE',
};

export default passwordResetConstants;
