import React, { useRef, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../helpers/i18n';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CodeInput from '../TwoFactorModal/subcomponents/CodeInput';
import { useHistory } from 'react-router-dom';

const TransferForm = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const codeInputsRef = useRef(null);
  const certIdRef = useRef(null);

  const validationSchema = yup.object({
    token: yup.string('Enter token').required(t('validation.required')),
    pinCode: yup
      .string('Enter PIN Code')
      .min(6, t('validation.required'))
      .required(t('validation.required')),
  });
  const formik = useFormik({
    initialValues: {
      token: '',
      pinCode: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => onFormSubmit(values),
  });

  const onFormSubmit = ({ token, pinCode }) => {
    history.push(`/${i18n.language}/${t('urls.certificateInvitePage')}/${token}?pin=${pinCode}`);
  };

  useEffect(() => {
    if (certIdRef.current) {
      certIdRef.current.focus();
    }
  }, [certIdRef]);

  return (
    <>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <h1>{t('transferPage.header')}</h1>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="token">
              <Form.Label>{t('transferPage.tokenPlaceholder')}</Form.Label>
              <Form.Control
                ref={certIdRef}
                isInvalid={formik.touched.token && !!formik.errors.token}
                spellCheck={false}
                autoComplete="off"
                name="token"
                autoCapitalize="none"
                value={formik.values.token}
                onChange={formik.handleChange}
                placeholder={t('transferPage.tokenPlaceholder')}
              />
              <Form.Control.Feedback
                className="font-weight-regular text text-body-small font-weight-medium"
                type="invalid"
              >
                {formik.errors.token}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formRegisterEmail">
              <Form.Label>{t('transferPage.pinCode')}</Form.Label>
              <Form.Control hidden isInvalid={formik.touched.pinCode && !!formik.errors.pinCode} />
              <CodeInput
                ref={codeInputsRef}
                codeLength={6}
                onValueChange={(newValue) => {
                  formik.setFieldValue('pinCode', newValue);
                }}
                isAutofocus={false}
                hasErrors={formik.touched.pinCode && !!formik.errors.pinCode}
              />
              <Form.Control.Feedback
                className="font-weight-regular text text-body-small font-weight-medium"
                type="invalid"
              >
                {formik.errors.pinCode}
              </Form.Control.Feedback>
            </Form.Group>
            <Button className="mt-6 w-100 btn-medium shadow-none" variant="primary" type="submit">
              {t('transferPage.button')}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default TransferForm;
