import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import CustomLink from '../../CustomLink/CutomLink';
import passwordResetActions from '../../../redux/actions/passwordReset.actions';
import { getUrlParam } from '../../../helpers/history';

const PasswordLostForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });
  const urlMail = getUrlParam('email') || '';

  const [email, setEmail] = useState(urlMail);
  const [emailError, setEmailError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  let submitButtonRef = useRef(null);
  const firstRender = useRef(true);

  const validateEmail = (value) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(value);
  };

  const validateForm = useCallback(() => {
    let isValid = true;
    if (email.length === 0 || !validateEmail(email)) {
      isValid = false;
    }
    setEmailError(!isValid);
    return isValid;
  }, [email]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (formSubmitted) {
      validateForm();
    }
  }, [validateForm, formSubmitted]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    submitButtonRef.blur();
    setFormSubmitted(true);

    if (validateForm()) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA, { action: 'submit' })
          .then((token) => {
            dispatch(passwordResetActions.remindPassword(email, token));
          });
      });
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          {!isDesktopVersion ? (
            <Col xs={12} className="m-0 p-0 d-flex justify-content-between mb-6">
              <h1 className="h2 mb-0">{t('passwordLostPage.header')}</h1>
              <Button variant="link link-large" className="pl-2 pt-0 pb-0 link">
                <CustomLink to="/">{t('header.loginRedirectLinkText')}</CustomLink>
              </Button>
            </Col>
          ) : (
            <h1>{t('passwordLostPage.header')}</h1>
          )}
          <Form onSubmit={onFormSubmit}>
            <Form.Group controlId="formRegisterEmail">
              <Form.Control
                isInvalid={emailError}
                spellCheck={false}
                autoComplete="off"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder={t('passwordLostPage.emailPlaceholder')}
              />
              <Form.Control.Feedback className="font-weight-regular text-body-small" type="invalid">
                {t('passwordLostPage.emailError')}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className="mt-5 w-100 btn-medium shadow-none"
              variant="primary"
              type="submit"
              ref={(buttonRefDOM) => {
                submitButtonRef = buttonRefDOM;
              }}
            >
              {t('passwordLostPage.button')}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PasswordLostForm;
