import { combineReducers } from 'redux';

import alerts from './alerts.reducer';
import responsive from './responsive.reducer';

import { twoFactor } from './twoFactor.reducer';

import register from './register.reducer';
import login from './login.reducer';
import passwordReset from './passwordReset.reducer';
import auth from './auth.reducer';
import identity from './identity.reducer';
import document from './document.reducer';
import filesModule from './filesModule.reducer';
import maintenanceStore from './maintenance.reducer';

const rootReducer = combineReducers({
  alerts,
  responsive,
  twoFactor,
  register,
  login,
  passwordReset,
  auth,
  identity,
  document,
  filesModule,
  maintenanceStore,
});

export default rootReducer;
