/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Nav, NavDropdown } from 'react-bootstrap';
import LanguageOption from './subcomponents/LanguageOption';
// eslint-disable-next-line import/no-cycle
import routes from '../../helpers/routes.config';
import cookieHelper from '../../helpers/cookieHelper';

const cookieLang = cookieHelper.getCookie('lang');

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const moreLanguagesDropdownVisible = false;

  const currentLanguage = i18n.language;
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });

  const prepareRouteRegexp = (path, currentLang) => {
    const routeParts = path.split('/');
    let finalRoute = '^';
    const regexForPathVariables = '([-a-zA-Z0-9]+)';
    // eslint-disable-next-line
    for (let i in routeParts) {
      // eslint-disable-next-line radix
      const isLast = parseInt(i) === routeParts.length - 1;
      if (routeParts[i].startsWith(':')) {
        finalRoute += regexForPathVariables;
      } else if (routeParts[i] !== currentLang && routeParts[i] !== '') {
        finalRoute += `(${routeParts[i]})`;
      }
      finalRoute += '\\/';
      if (isLast) {
        finalRoute += '?$';
      }
    }
    return finalRoute;
  };

  const prepareTranslatedRoute = (sourceRoute, targetRoute, targetLang) => {
    const sourceParts = sourceRoute.split('/');
    const targetParts = targetRoute.split('/');
    const finalPath = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const i in targetParts) {
      if (targetParts[i].startsWith(':')) {
        finalPath.push(sourceParts[i]);
      } else {
        finalPath.push(targetParts[i]);
      }
    }
    finalPath.splice(1, 0, targetLang);
    return finalPath.join('/');
  };

  const onChangeLanguage = (lang) => {
    const currentLang = i18n.language;
    if (lang === 'pl' || lang === 'en') {
      let location = window.location.pathname;
      i18n.changeLanguage(lang).then(() => {
        let finalRoute = '';
        cookieHelper.setCookie('lang', lang);
        routes.forEach((route) => {
          if (route.type === 'route') {
            const routeLocales = Object.keys(route.locales);
            for (const i in routeLocales) {
              const locale = routeLocales[i];
              const regexp = prepareRouteRegexp(route.locales[locale].path, currentLang);
              if (location.startsWith(`/${locale}`)) {
                location = location.substr(`/${locale}`.length);
                if (location === '') {
                  location = '/';
                }
              }
              const match = location.match(regexp);
              if (match) {
                if (Object.prototype.hasOwnProperty.call(route.locales, lang)) {
                  if (route.title) {
                    document.title = t(route.title);
                  } else {
                    document.title = t('titles.default');
                  }
                  finalRoute = prepareTranslatedRoute(location, route.locales[lang].path, lang);
                }
              }
            }
          }
        });

        if (finalRoute[0] !== '/') {
          finalRoute = `/${finalRoute}`;
        }

        window.history.pushState({}, document.title, finalRoute);
      });
    }
  };

  // fix overflow issue on dropdown open
  const toggleBodyOverflow = () => {
    document.getElementsByClassName('app-wrapper')[0].style.overflow = 'hidden';
    setTimeout(() => {
      document.getElementsByClassName('app-wrapper')[0].style.overflow = 'initial';
    }, 1000);
  };

  const translateLanguageCodeToLabel = (code) => {
    switch (code) {
      case 'pl':
        return 'Polski';
      case 'en':
        return 'English';
      default:
        return 'Polski';
    }
  };

  // On mount, check if lang cookie exist, if yes then redirect to proper lang
  useEffect(() => {
    let location = window.location.pathname;
    if (cookieLang && !location.startsWith(`/${cookieLang}`)) {
      onChangeLanguage(cookieLang);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isDesktopVersion ? (
        <Nav onSelect={onChangeLanguage} className="justify-content-start">
          <LanguageOption label="Polski" lang="pl" isActive={currentLanguage === 'pl'} />
          <LanguageOption label="English" lang="en" isActive={currentLanguage === 'en'} />
          {moreLanguagesDropdownVisible && (
            <NavDropdown className="mono" title="More" onClick={() => toggleBodyOverflow()}>
              <NavDropdown.Item eventKey="lang">Language</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      ) : (
        <Nav onSelect={onChangeLanguage} className="justify-content-start">
          <NavDropdown
            className="color"
            title={translateLanguageCodeToLabel(currentLanguage)}
            onClick={() => toggleBodyOverflow()}
          >
            <NavDropdown.Item eventKey="pl">Polski</NavDropdown.Item>
            <NavDropdown.Item eventKey="en">English</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      )}
    </>
  );
};

export default LanguageSwitcher;
