import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import PasswordLostForm from '../../components/pages/PasswordLost/PasswordLostForm';
import passwordResetActions from '../../redux/actions/passwordReset.actions';
import ConfirmationScreen from '../../components/ConfirmationScreen/ConfirmationScreen';
import { REQUEST_STATUS } from '../../models/common.model';

const PasswordLostPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const passwordRemindStatus = useSelector((state) => state.passwordReset.passwordRemindStatus);
  const remindedEmail = useSelector((state) => state.passwordReset.remindedEmail);

  const [wasSent, setWasSent] = useState(false);

  useEffect(() => {
    if (passwordRemindStatus === REQUEST_STATUS.SUCCESS) {
      setWasSent(true);
    }
  }, [passwordRemindStatus]);

  useEffect(() => {
    document.title = t('titles.default');
    return () => {
      dispatch(passwordResetActions.resetStore());
    };
  }, [dispatch, t]);

  const handleResendEmail = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA, { action: 'submit' })
        .then((token) => {
          dispatch(passwordResetActions.remindPassword(remindedEmail, token));
        });
    });
  };

  return (
    <>
      <Container className="d-flex flex-column h-100">
        <Row className="pl-3 pr-3">
          <Col>
            <Header redirectType="login" />
          </Col>
        </Row>
        <Row className="p-3 flex-fill align-items-center">
          <Col>
            {passwordRemindStatus === REQUEST_STATUS.SUCCESS || wasSent ? (
              <ConfirmationScreen
                imgSrc="/images/check-mail.svg"
                header={t('passwordLostPage.successPage.header')}
                text={t('passwordLostPage.successPage.infoText')}
                email={remindedEmail}
                resendEmail={handleResendEmail}
              />
            ) : (
              <PasswordLostForm />
            )}
          </Col>
        </Row>
        {passwordRemindStatus !== REQUEST_STATUS.SUCCESS && !wasSent && (
          <Row className="pl-3 pr-3">
            <Col>
              <Footer />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default PasswordLostPage;
