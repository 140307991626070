import { createBrowserHistory } from 'history';

export default createBrowserHistory();

export function getUrlParam(name, url) {
  if (!url) url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regexS = '[\\?&]' + name + '=([^&#]*)';
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  return results == null ? null : results[1];
}
