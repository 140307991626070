import { toast } from 'react-toastify';
import alertsConstants from '../constants/alerts.constants';

const alertsActions = {
  success,
  error,
  info,
  clear,
};

function success(message) {
  toast.success(message);
  return { type: alertsConstants.SHOW_SUCCESS_ALERT, message };
}

function info(message) {
  toast.info(message);
  return { type: alertsConstants.SHOW_INFO_ALERT, message };
}

function error(message) {
  toast.error(message);
  return { type: alertsConstants.SHOW_ERROR_ALERT, message };
}

function clear() {
  return { type: alertsConstants.CLEAR_ALERT };
}

export default alertsActions;
