import gaHelper from '../../helpers/gaHelper';
import registerConstants from '../constants/register.constants';
import registerService from '../services/register.service';
import alertsActions from './alerts.actions';

const registerActions = {
  register,
  resetRegisterStatus,
  addPhoneNumber,
  setPassword,
  reSendActivationEmail,
  getRegisterCodeStatus,
  confirmPhoneNumber,
  reSendPhoneConfirmationSms,
};

function register(email, language, captchaToken) {
  return (dispatch) => {
    dispatch({ type: registerConstants.REGISTER_REQUEST });
    registerService
      .register(email, language, captchaToken)
      .then((response) => {
        if (!response.errors) {
          dispatch({ type: registerConstants.REGISTER_SUCCESS, response: response.data });
          gaHelper.initialize();
          gaHelper.sendEvent('RegistrationStarted');
        } else {
          dispatch({ type: registerConstants.REGISTER_FAILURE, error: response.errors[0].message });
          dispatch(alertsActions.error(response.errors[0].message));
        }
      })
      .catch((error) => {
        dispatch({ type: registerConstants.REGISTER_FAILURE, error });
        dispatch(alertsActions.error(error));
      });
  };
}

function reSendActivationEmail(email) {
  return (dispatch) => {
    dispatch({ type: registerConstants.RE_SEND_ACTIVATION_EMAIL_REQUEST });
    registerService
      .reSendActivationEmail(email)
      .then(() => {
        dispatch({ type: registerConstants.RE_SEND_ACTIVATION_EMAIL_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: registerConstants.RE_SEND_ACTIVATION_EMAIL_FAILURE });
      });
  };
}

function getRegisterCodeStatus(code) {
  return (dispatch) => {
    dispatch({ type: registerConstants.GET_ACTIVATION_CODE_STATUS_REQUEST });
    registerService
      .getRegisterCodeStatus(code)
      .then((response) => {
        dispatch({
          type: registerConstants.GET_ACTIVATION_CODE_STATUS_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: registerConstants.GET_ACTIVATION_CODE_STATUS_FAILURE, error });
      });
  };
}

function resetRegisterStatus() {
  return { type: registerConstants.RESET_REGISTER_STATUS };
}

function addPhoneNumber(code, prefix, number) {
  return (dispatch) => {
    dispatch({ type: registerConstants.ADD_PHONE_NUMBER_REQUEST });
    registerService
      .setPhoneNumber(code, prefix, number)
      .then(() => {
        dispatch({ type: registerConstants.ADD_PHONE_NUMBER_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: registerConstants.ADD_PHONE_NUMBER_FAILURE, error });
      });
  };
}

function confirmPhoneNumber(activationCode, smsCode) {
  return (dispatch) => {
    dispatch({ type: registerConstants.VERIFY_PHONE_NUMBER_REQUEST });
    registerService
      .confirmPhoneNumber(activationCode, smsCode)
      .then(() => {
        dispatch({ type: registerConstants.VERIFY_PHONE_NUMBER_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: registerConstants.VERIFY_PHONE_NUMBER_FAILURE, error });
      });
  };
}

function reSendPhoneConfirmationSms(activationCode) {
  return (dispatch) => {
    dispatch({ type: registerConstants.RE_SEND_CONFIRMATION_CODE_REQUEST });
    registerService
      .reSendConfirmPhoneCode(activationCode)
      .then(() => {
        dispatch({ type: registerConstants.RE_SEND_CONFIRMATION_CODE_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: registerConstants.RE_SEND_CONFIRMATION_CODE_FAILURE, error });
      });
  };
}

// TODO: dummy password step increment
function setPassword(activationCode, password) {
  return (dispatch) => {
    dispatch({ type: registerConstants.SET_PASSWORD_REQUEST });
    registerService
      .setPassword(activationCode, password)
      .then(() => {
        dispatch({ type: registerConstants.SET_PASSWORD_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: registerConstants.SET_PASSWORD_FAILURE, error });
      });
  };
}

export default registerActions;
