import passwordResetConstants from '../constants/passwordReset.constants';
import { REQUEST_STATUS } from '../../models/common.model';

const initialState = {
  passwordRemindStatus: REQUEST_STATUS.IDLE,
  passwordResetStatus: REQUEST_STATUS.IDLE,
  checkPasswordStatus: REQUEST_STATUS.IDLE,
  remindedEmail: null,
};

function passwordReset(state = initialState, action) {
  switch (action.type) {
    case passwordResetConstants.REMIND_PASSWORD_REQUEST:
      return {
        ...state,
        passwordRemindStatus: REQUEST_STATUS.PENDING,
      };
    case passwordResetConstants.REMIND_PASSWORD_SUCCESS:
      return {
        ...state,
        remindedEmail: action.email,
        passwordRemindStatus: REQUEST_STATUS.SUCCESS,
      };
    case passwordResetConstants.REMIND_PASSWORD_FAILURE:
      return {
        ...state,
        passwordRemindStatus: REQUEST_STATUS.ERROR,
      };
    case passwordResetConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        passwordResetStatus: REQUEST_STATUS.PENDING,
      };
    case passwordResetConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: REQUEST_STATUS.SUCCESS,
      };
    case passwordResetConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        passwordResetStatus: REQUEST_STATUS.ERROR,
      };
    case passwordResetConstants.CHECK_RESET_PASSWORD_TOKEN_REQUEST:
      return {
        ...state,
        checkPasswordStatus: REQUEST_STATUS.PENDING,
      };
    case passwordResetConstants.CHECK_RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        checkPasswordStatus: REQUEST_STATUS.SUCCESS,
      };
    case passwordResetConstants.CHECK_RESET_PASSWORD_TOKEN_FAILURE:
      return {
        ...state,
        checkPasswordStatus: REQUEST_STATUS.ERROR,
      };
    case passwordResetConstants.RESET_STORE:
      return initialState;
    default:
      return state;
  }
}

export default passwordReset;
