/* eslint-disable no-console */
import axios from '../../helpers/axios';

const twoFactorService = {
  initTwoFactorSession,
  sendTwoFactor,
  reSendTwoFactorCode,
};

function initTwoFactorSession(permissions) {
  return axios
    .post('/auth/security/2fa', { permissions }, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function sendTwoFactor(code, twoFactorId) {
  return axios
    .post(
      '/auth/two-factor',
      {
        code,
      },
      {
        withCredentials: true,
        headers: {
          twoFactorId,
        },
      }
    )
    .then((response) => response)
    .catch((error) => Promise.reject(error.response.data.errors));
}

function reSendTwoFactorCode(twoFactorId) {
  return axios
    .post(
      '/auth/two-factor/resend',
      {},
      {
        withCredentials: true,
        headers: {
          twoFactorId,
        },
      }
    )
    .then((response) => response)
    .catch((error) => Promise.reject(error.response.data.errors));
}

export default twoFactorService;
