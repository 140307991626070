Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.hexToRgb = hexToRgb;
exports.hslToRgb = hslToRgb;
exports.rgbToHex = rgbToHex;
exports.getLuminance = getLuminance;
exports.getContrastRatio = getContrastRatio;
exports.decomposeColor = decomposeColor;
exports.recomposeColor = recomposeColor;
exports.emphasize = emphasize;
exports.lighten = lighten;
exports.darken = darken;
exports.fade = fade;

function clamp(value) {
  const min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

  return Math.min(Math.max(min, value), max);
}

function intToHex(int) {
  const hex = int.toString(16);
  return hex.length === 1 ? '0'.concat(hex) : hex;
}

function hexToRgb(color) {
  color = color.substr(1);
  const re = new RegExp('.{1,'.concat(color.length >= 6 ? 2 : 1, '}'), 'g');
  let colors = color.match(re);

  if (colors && colors[0].length === 1) {
    colors = colors.map(function (n) {
      return n + n;
    });
  }

  return colors
    ? 'rgb'.concat(colors.length === 4 ? 'a' : '', '(').concat(
        colors
          .map(function (n, index) {
            return index < 3 ? parseInt(n, 16) : Math.round((parseInt(n, 16) / 255) * 1000) / 1000;
          })
          .join(', '),
        ')'
      )
    : '';
}

function hslToRgb(color) {
  color = decomposeColor(color);
  const _color = color;
  const { values } = _color;
  const h = values[0];
  const s = values[1] / 100;
  const l = values[2] / 100;
  const a = s * Math.min(l, 1 - l);

  const f = function f(n) {
    const k = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (n + h / 30) % 12;
    return l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  };

  let type = 'rgb';
  const rgb = [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)];

  if (color.type === 'hsla') {
    type += 'a';
    rgb.push(values[3]);
  }

  return recomposeColor({
    type,
    values: rgb,
  });
}

function rgbToHex(color) {
  // Idempotent
  if (color.indexOf('#') === 0) {
    return color;
  }

  const _decomposeColor = decomposeColor(color);
  const { values } = _decomposeColor;

  return '#'.concat(
    values
      .map(function (n) {
        return intToHex(n);
      })
      .join('')
  );
}

function recomposeColor(color) {
  const { type } = color;
  let { values } = color;

  if (type.indexOf('rgb') !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    values = values.map(function (n, i) {
      return i < 3 ? parseInt(n, 10) : n;
    });
  } else if (type.indexOf('hsl') !== -1) {
    values[1] = ''.concat(values[1], '%');
    values[2] = ''.concat(values[2], '%');
  }

  return ''.concat(type, '(').concat(values.join(', '), ')');
}

function decomposeColor(color) {
  if (color.type) {
    return color;
  }

  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }

  const marker = color.indexOf('(');
  const type = color.substring(0, marker);

  let values = color.substring(marker + 1, color.length - 1).split(',');
  values = values.map(function (value) {
    return parseFloat(value);
  });
  return {
    type,
    values,
  };
}

function getLuminance(color) {
  color = decomposeColor(color);
  let rgb = color.type === 'hsl' ? decomposeColor(hslToRgb(color)).values : color.values;
  rgb = rgb.map(function (val) {
    val /= 255; // normalized

    return val <= 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4);
  }); // Truncate at 3 digits

  return Number((0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]).toFixed(3));
}

function getContrastRatio(foreground, background) {
  const lumA = getLuminance(foreground);
  const lumB = getLuminance(background);
  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05);
}

function emphasize(color) {
  const coefficient = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.15;
  return getLuminance(color) > 0.5 ? darken(color, coefficient) : lighten(color, coefficient);
}

function lighten(color, coefficient) {
  color = decomposeColor(color);
  coefficient = clamp(coefficient);

  if (color.type.indexOf('hsl') !== -1) {
    color.values[2] += (100 - color.values[2]) * coefficient;
  } else if (color.type.indexOf('rgb') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      color.values[i] += (255 - color.values[i]) * coefficient;
    }
  }

  return recomposeColor(color);
}

function darken(color, coefficient) {
  color = decomposeColor(color);
  coefficient = clamp(coefficient);

  if (color.type.indexOf('hsl') !== -1) {
    color.values[2] *= 1 - coefficient;
  } else if (color.type.indexOf('rgb') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      color.values[i] *= 1 - coefficient;
    }
  }

  return recomposeColor(color);
}

function fade(color, value) {
  color = decomposeColor(color);
  value = clamp(value);

  if (color.type === 'rgb' || color.type === 'hsl') {
    color.type += 'a';
  }

  color.values[3] = value;
  return recomposeColor(color);
}
