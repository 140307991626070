import identityConstants from '../constants/identity.constants';
import { REQUEST_STATUS } from '../../models/common.model';

const initialState = {
  invitationDetails: null,
  invitationDetailStatus: REQUEST_STATUS.IDLE,
  acceptInvitationStatus: REQUEST_STATUS.IDLE,
  joinedWorkspaceIdentityId: null,
  registerIntoWorkspaceStatus: REQUEST_STATUS.IDLE,
  registerConfirmationToken: null,

  registerIntoWorkspaceErrors: null,
};

function identity(state = initialState, action) {
  switch (action.type) {
    case identityConstants.FETCH_INVITATION_DETAILS_REQUEST:
      return {
        ...state,
        invitationDetailStatus: REQUEST_STATUS.PENDING,
      };
    case identityConstants.FETCH_INVITATION_DETAILS_SUCCESS:
      return {
        ...state,
        invitationDetailStatus: REQUEST_STATUS.SUCCESS,
        invitationDetails: action.invitationDetails,
      };
    case identityConstants.FETCH_INVITATION_DETAILS_FAILURE:
      return {
        ...state,
        invitationDetailStatus: REQUEST_STATUS.ERROR,
      };
    case identityConstants.JOIN_WORKSPACE_REQUEST:
      return {
        ...state,
        acceptInvitationStatus: REQUEST_STATUS.PENDING,
      };
    case identityConstants.JOIN_WORKSPACE_SUCCESS:
      return {
        ...state,
        acceptInvitationStatus: REQUEST_STATUS.SUCCESS,
        joinedWorkspaceIdentityId: action.identityData,
      };
    case identityConstants.JOIN_WORKSPACE_FAILURE:
      return {
        ...state,
        acceptInvitationStatus: REQUEST_STATUS.ERROR,
      };
    case identityConstants.REGISTER_INTO_WORKSPACE_REQUEST:
      return {
        ...state,
        registerIntoWorkspaceStatus: REQUEST_STATUS.PENDING,
      };
    case identityConstants.REGISTER_INTO_WORKSPACE_SUCCESS:
      return {
        ...state,
        registerIntoWorkspaceStatus: REQUEST_STATUS.SUCCESS,
        registerConfirmationToken: action.confirmCode,
      };
    case identityConstants.REGISTER_INTO_WORKSPACE_FAILURE:
      return {
        ...state,
        registerIntoWorkspaceStatus: REQUEST_STATUS.ERROR,
        registerIntoWorkspaceErrors: action.error,
      };
    default:
      return state;
  }
}

export default identity;
