const redirectHelper = {
  setRedirect,
  redirect,
};

function setRedirect(path) {
  const finalPath = checkPath(path);
  if (finalPath) {
    window.localStorage.setItem('redirectAfter', finalPath);
    return true;
  }
  return false;
}

function checkPath(path) {
  const pathUrl = isAbsolute(path, true);
  if (pathUrl) {
    let targetProtocol = window.location.protocol;
    /*
     * Check if target protocol is at least as safe as current, if is better then upgrade;
     * */
    if (pathUrl.protocol !== targetProtocol && pathUrl.protocol === 'https:') {
      targetProtocol = pathUrl.protocol;
    }

    /*
     * Check if we are redirecting within current domain, allow to redirect within subdomains and different protocols
     * */
    const pathHostParts = pathUrl.hostname.split('.');
    const pathAbsoluteHost = `${pathHostParts[pathHostParts.length - 2]}.${
      pathHostParts[pathHostParts.length - 1]
    }`;
    const currentHostParts = window.location.hostname.split('.');
    const currentAbsoluteHost = `${currentHostParts[currentHostParts.length - 2]}.${
      currentHostParts[currentHostParts.length - 1]
    }`;

    if (currentAbsoluteHost !== pathAbsoluteHost) {
      /*
       * Target domain is not within our current domain, abort!
       * */
      console.warn('Redirect host mismatch!');
      return false;
    }
    pathUrl.protocol = targetProtocol;
    return pathUrl.toString();
  }
  return path;
}

function isAbsolute(path, returnUrlObject) {
  let pathUrl = false;
  let isAbsoluteRedirect = true;
  /*
   * if path is absolute, then we will create URL object successfully, otherwise it's most probably local path
   * */
  try {
    pathUrl = new URL(path);
  } catch (e) {
    isAbsoluteRedirect = false;
  }
  if (returnUrlObject) {
    return pathUrl;
  }
  return isAbsoluteRedirect;
}

function redirect(historyObject) {
  /*
   * Read and remove redirect target from localStorage;
   * */
  const targetPath = window.localStorage.getItem('redirectAfter');
  window.localStorage.removeItem('redirectAfter');
  const pathIsAbsolute = isAbsolute(targetPath);

  if (pathIsAbsolute || !targetPath) {
    /*
     * check if path is valid and within our domain scope, if so navigate to it,
     * otherwise navigate to default app url
     * */
    const validatedPath = checkPath(targetPath);
    if (validatedPath) {
      window.location.href = validatedPath;
    } else {
      window.location.href = process.env.REACT_APP_APP_URL;
    }
  } else if (!historyObject) {
    /*
     * If caller didn't provided history object from react-router-dom, fallback to window location object
     * */
    window.location.pathname = targetPath;
  } else {
    /*
     * If caller provided history object from react-router-dom, use it to make smooth transition to next page
     * */
    historyObject.push(targetPath);
  }
}

export default redirectHelper;
