import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../translations/en.json';
import pl from '../translations/pl.json';
import cookieHelper from './cookieHelper';
const cookieLang = cookieHelper.getCookie('lang');

const customLanguageDetector = {
  type: 'languageDetector',
  async: false, // If this is set to true, your detect function receives a callback function that you should call with your language, useful to retrieve your language stored in AsyncStorage for example
  init: function (services, detectorOptions, i18nextOptions) {
    this.supportedLngs = i18nextOptions.supportedLngs;
    this.failbackLng = i18nextOptions.fallbackLng;
  },
  detect: function () {
    let lang = this.failbackLng[0];
    const langIndex = process.env.REACT_APP_LANG_SCHEMA_INDEX;
    const locationParts = window.location.pathname.split('/');

    if (this.supportedLngs.includes(cookieLang)) {
      return cookieLang;
    }
    if (locationParts.length > langIndex) {
      if (this.supportedLngs.indexOf(locationParts[langIndex]) !== -1) {
        lang = locationParts[langIndex];
        cookieHelper.setCookie('lang', lang);
        return lang;
      }
    }
    if (this.supportedLngs.indexOf(cookieLang.toLowerCase()) !== -1) {
      lang = cookieLang.toLowerCase();
      return lang;
    }
    let browserLang = window.navigator.language;
    if (browserLang.length > 4) {
      browserLang = browserLang.substr(0, 2);
      if (this.supportedLngs.indexOf(browserLang) !== -1) {
        lang = browserLang;
      }
    }
    return lang;
  },
  cacheUserLanguage: function (lng) {},
};

i18n
  .use(initReactI18next)
  .use(customLanguageDetector)
  .init({
    supportedLngs: ['pl', 'en'],
    resources: {
      en: {
        translation: en,
      },
      pl: {
        translation: pl,
      },
    },
    fallbackLng: 'en',

    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export default i18n;
