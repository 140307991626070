import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from '../../helpers/i18n';

const CustomLink = (props) => {
  const { to, linkProps, children } = props;
  return (
    <Link to={`/${i18n.language}${to}`} {...linkProps}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string,
  linkProps: PropTypes.node,
  children: PropTypes.node,
};

export default CustomLink;
