const identityConstants = {
  FETCH_INVITATION_DETAILS_REQUEST: 'FETCH_INVITATION_DETAILS_REQUEST',
  FETCH_INVITATION_DETAILS_SUCCESS: 'FETCH_INVITATION_DETAILS_SUCCESS',
  FETCH_INVITATION_DETAILS_FAILURE: 'FETCH_INVITATION_DETAILS_FAILURE',

  JOIN_WORKSPACE_REQUEST: 'JOIN_WORKSPACE_REQUEST',
  JOIN_WORKSPACE_SUCCESS: 'JOIN_WORKSPACE_SUCCESS',
  JOIN_WORKSPACE_FAILURE: 'JOIN_WORKSPACE_FAILURE',

  REGISTER_INTO_WORKSPACE_REQUEST: 'REGISTER_INTO_WORKSPACE_REQUEST',
  REGISTER_INTO_WORKSPACE_SUCCESS: 'REGISTER_INTO_WORKSPACE_SUCCESS',
  REGISTER_INTO_WORKSPACE_FAILURE: 'REGISTER_INTO_WORKSPACE_FAILURE',
};

export default identityConstants;
