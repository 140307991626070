import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams, useHistory } from 'react-router-dom';
import FullPageModal from '../../components/FullPageModal/FullPageModal';
import ConfirmationScreen from '../../components/ConfirmationScreen/ConfirmationScreen';
import Header from '../../components/Header/Header';
import CustomLink from '../../components/CustomLink/CutomLink';
import PasswordForm from '../../components/PasswordForm/PasswordForm';
import passwordResetActions from '../../redux/actions/passwordReset.actions';
import Loader from '../../components/Loader/Loader';
import { REQUEST_STATUS } from '../../models/common.model';

const PasswordRestartPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });

  const routerParams = useParams();
  const routerHistory = useHistory();

  const resetToken = routerParams.code;

  const passwordResetStatus = useSelector((state) => {
    return state.passwordReset.passwordResetStatus;
  });
  const checkPasswordStatus = useSelector((state) => {
    return state.passwordReset.checkPasswordStatus;
  });

  useEffect(() => {
    document.title = t('titles.default');
    dispatch(passwordResetActions.checkResetToken(resetToken));
    return () => {
      dispatch(passwordResetActions.resetStore());
    };
  }, [dispatch, resetToken, t]);

  useEffect(() => {
    if (checkPasswordStatus === REQUEST_STATUS.ERROR) {
      /*
       * If code is invalid, navigate to login, if 404 page would exist, then we could navigate there
       * */
      routerHistory.push('/');
    }
  }, [checkPasswordStatus, routerHistory]);

  const getModalContent = () => {
    if (checkPasswordStatus === REQUEST_STATUS.SUCCESS) {
      return <PasswordForm type="passwordRestartPage" activationCode={resetToken} />;
    }
    return <Loader />;
  };

  return passwordResetStatus !== REQUEST_STATUS.SUCCESS ? (
    <FullPageModal isOpen>{getModalContent()}</FullPageModal>
  ) : (
    <>
      <Container className="d-flex flex-column h-100">
        <Row className="pl-3 pr-3">
          <Col>
            <Header redirectType="login" />
          </Col>
        </Row>
        <Row className="p-3 flex-fill align-items-center">
          <Col>
            <ConfirmationScreen
              imgSrc="/images/password-changed.svg"
              header={t('passwordRestartPage.successPage.header')}
              text={t('passwordRestartPage.successPage.infoText')}
              buttonLabel={t('passwordRestartPage.successPage.button')}
              buttonLink="/"
            />
          </Col>
        </Row>
        {!isDesktopVersion && (
          <Row className="pl-3 pr-3">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center flex-column flex-sm-row"
            >
              <span className="mb-0 font-weight-medium text-center">
                {t('header.loginRedirectText')}
              </span>
              <Button variant="link link-large" className="pl-2 pt-0 pb-0 link">
                <CustomLink to="/">{t('header.loginRedirectLinkText')}</CustomLink>
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default PasswordRestartPage;
