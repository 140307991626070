import React from 'react';
import { Col, Nav, Row, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import links from '../../helpers/mainLinks';
import i18n from '../../helpers/i18n';

const Footer = () => {
  const { t } = useTranslation();
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });
  const { language } = i18n;
  const mainLinks = links(language);

  // fix overflow issue on dropdown open
  const toggleBodyOverflow = () => {
    document.getElementsByClassName('app-wrapper')[0].style.overflow = 'hidden';
    setTimeout(() => {
      document.getElementsByClassName('app-wrapper')[0].style.overflow = 'initial';
    }, 1000);
  };

  return (
    <Row className="flex-row footer align-items-center" noGutters>
      <Col xs={6} className="color">
        <LanguageSwitcher />
      </Col>
      <Col xs={6} className="mono">
        {isDesktopVersion ? (
          <Nav className="justify-content-end">
            <Nav.Link className="nav-link-mono" href={mainLinks.privacyPolicy} target="_blank">
              {t('footer.links.privacyPolicy')}
            </Nav.Link>
            <Nav.Link className="nav-link-mono" href={mainLinks.termsOfService} target="_blank">
              {t('footer.links.termsOfService')}
            </Nav.Link>
            <Nav.Link className="nav-link-mono" href={mainLinks.helpDesk} target="_blank">
              {t('footer.links.helpDocs')}
            </Nav.Link>
          </Nav>
        ) : (
          <Nav className="justify-content-end">
            <NavDropdown
              className="mono"
              title={t('footer.links.privacyPolicy')}
              onClick={() => toggleBodyOverflow()}
              onPointerDown={() => toggleBodyOverflow()}
            >
              <NavDropdown.Item href={mainLinks.privacyPolicy} target="_blank" eventKey="1">
                {t('footer.links.privacyPolicy')}
              </NavDropdown.Item>
              <NavDropdown.Item href={mainLinks.termsOfService} target="_blank" eventKey="2">
                {t('footer.links.termsOfService')}
              </NavDropdown.Item>
              <NavDropdown.Item href={mainLinks.helpDesk} target="_blank" eventKey="3">
                {t('footer.links.helpDocs')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        )}
      </Col>
    </Row>
  );
};

export default Footer;
