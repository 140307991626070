import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FullPageModal from '../FullPageModal/FullPageModal';
import { twoFactorActions } from '../../redux/actions/twoFactor.actions';
import axios from '../../helpers/axios';
import TwoFactorForm from './subcomponents/TwoFactorForm';
import { REQUEST_STATUS } from '../../models/common.model';

class TwoFactorModal extends React.Component {
  state = {
    hasErrors: false,
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      twoFactorSendStatus,
      twoFactorActive,
      onResolution,
      twoFactorInitStatus,
      twoFactorInitError,
    } = this.props;

    if (!prevProps.twoFactorActive && twoFactorActive) {
      this.startNewTwoFactorSession();
    }

    if (
      prevProps.twoFactorInitStatus !== REQUEST_STATUS.ERROR &&
      twoFactorInitStatus === REQUEST_STATUS.ERROR
    ) {
      onResolution(REQUEST_STATUS.ERROR, twoFactorInitError);
    }

    if (
      prevProps.twoFactorSendStatus === REQUEST_STATUS.PENDING &&
      twoFactorSendStatus === REQUEST_STATUS.SUCCESS
    ) {
      /*
       * code has been successfully send, now we should have token to authorize original request
       * lets resend it
       * */
      // this.retryRequest(); the hell
      this.changeErrorState(false);
    }
    if (
      prevProps.twoFactorSendStatus !== REQUEST_STATUS.ERROR &&
      twoFactorSendStatus === REQUEST_STATUS.ERROR
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.changeErrorState(true);
    }
  }

  /*
   * Handle send code to acquire 2FA token
   * */
  onSend(code) {
    const { onResolution, twoFactorToken, modalMode } = this.props;
    if (modalMode === 'initial') {
      onResolution(REQUEST_STATUS.SUCCESS, {
        twoFactorCode: code,
        twoFactorToken: twoFactorToken.token,
      });
    } else {
      this.retryRequest(code);
    }
  }

  /*
   * Handle resend code if 2FA method not prevents it
   * */
  onReSend() {
    // const { reSendTwoFactorCode, twoFactorId } = this.props;
    // reSendTwoFactorCode(twoFactorId);
    this.startNewTwoFactorSession();
  }

  startNewTwoFactorSession() {
    const { requestData, initTwoFactorSession } = this.props;
    this.changeErrorState(false);
    initTwoFactorSession(requestData.withPermissions);
  }

  /*
   * Resend original request interrupted by 2FA with acquired 2FA token
   * */
  retryRequest(code) {
    const {
      requestData,
      twoFactorToken,
      onResolution,
      closeTwoFactor,
      token,
      shortToken,
    } = this.props;
    const { method, url, data, headers } = requestData;
    const finalToken = token || shortToken;

    /*
     * inject 2FA session headers to authorize request
     * */
    const newHeaders = {
      ...headers,
      'Two-Factor-Token': twoFactorToken.token,
      'Two-Factor-Code': code,
      Authorization: finalToken.token,
    };

    /*
     * Rebuild config for request
     * */
    const axiosConfig = {
      method,
      url,
      headers: newHeaders,
      twoFactorSigned: true,
      withCredentials: true,
    };

    /*
     * if request could have payload, inject it to config
     * */
    if (['post', 'put'].indexOf(method) !== -1) {
      /*
       * try to decode JSON object, if not possible fallback to plaintext
       * */
      try {
        axiosConfig.data = JSON.parse(data);
      } catch (error) {
        axiosConfig.data = data;
      }
    }

    /*
     * Execute request, and pass response to callback in App.js so it could be passed back to original promise
     * */
    axios(axiosConfig)
      .then((response) => {
        onResolution(REQUEST_STATUS.SUCCESS, response);
      })
      .catch((error) => {
        if (error.response.status === 403 && error.response.data.errors[0].code === 'FORBIDDEN') {
          this.changeErrorState(true);
        } else {
          onResolution(REQUEST_STATUS.ERROR, error);
          closeTwoFactor();
        }
      });

    /*
     * Our job is done here, close 2FA modal and reset store
     * */
  }

  changeErrorState(newState) {
    this.setState({ hasErrors: newState });
  }

  render() {
    const {
      twoFactorActive,
      twoFactorMethod,
      twoFactorInitTimestamp,
      twoFactorSendStatus,
      twoFactorReSendStatus,
      twoFactorToken,
      closeTwoFactor,
    } = this.props;

    const { hasErrors } = this.state;

    const isSending =
      twoFactorSendStatus === REQUEST_STATUS.PENDING ||
      twoFactorSendStatus === REQUEST_STATUS.SUCCESS;
    const isReSending = twoFactorReSendStatus === REQUEST_STATUS.PENDING;
    return (
      <>
        {twoFactorActive && twoFactorMethod && (
          <FullPageModal
            isOpen={twoFactorActive}
            onClose={() => closeTwoFactor()}
            showLogo
            showClose={false}
          >
            <TwoFactorForm
              method={twoFactorMethod}
              twoFactorToken={twoFactorToken}
              hasErrors={hasErrors}
              isSending={isSending}
              isReSending={isReSending}
              lastSendTimestamp={twoFactorInitTimestamp}
              onClearErrors={() => {
                this.changeErrorState(false);
              }}
              onReSend={() => {
                this.onReSend();
              }}
              onSend={(code) => {
                this.onSend(code);
              }}
            />
          </FullPageModal>
        )}
      </>
    );
  }
}

function mapState(state) {
  const {
    twoFactorActive,
    requestData,
    twoFactorMethod,
    twoFactorId,
    twoFactorToken,
    twoFactorInitTimestamp,
    twoFactorSendStatus,
    twoFactorReSendStatus,
    modalMode,
    twoFactorInitStatus,
    twoFactorInitError,
  } = state.twoFactor;
  const { shortToken, token } = state.login;
  return {
    twoFactorActive,
    requestData,
    twoFactorMethod,
    twoFactorId,
    twoFactorToken,
    twoFactorInitTimestamp,
    twoFactorSendStatus,
    twoFactorReSendStatus,
    shortToken,
    token,
    modalMode,
    twoFactorInitStatus,
    twoFactorInitError,
  };
}

const actionCreators = {
  startTwoFactor: twoFactorActions.startTwoFactor,
  sendTwoFactor: twoFactorActions.sendTwoFactor,
  closeTwoFactor: twoFactorActions.closeTwoFactor,
  reSendTwoFactorCode: twoFactorActions.reSendTwoFactorCode,
  initTwoFactorSession: twoFactorActions.initTwoFactorSession,
  process2FARequest: twoFactorActions.process2FARequest,
};

TwoFactorModal.propTypes = {
  onResolution: PropTypes.func,
};

const translatedTwoFactorModal = withTranslation()(TwoFactorModal);
const connectedTwoFactorModal = connect(mapState, actionCreators)(translatedTwoFactorModal);

// eslint-disable-next-line import/prefer-default-export
export { connectedTwoFactorModal as TwoFactorModal };
