import axios from '../../helpers/axios';

const loginService = {
  preLogin,
  login,
};

function preLogin(email, password, captchaToken) {
  return axios
    .post(
      `/auth/token/password`,
      {
        userName: email,
        password,
      },
      {
        headers: {
          Captcha: captchaToken,
        },
        withCredentials: true,
      }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function login(token, extended = false) {
  const query = extended ? '?extended=true' : '';
  return axios
    .post(
      `/auth/token/authorization${query}`,
      {},
      {
        withPermissions: ['AUTH_USER_POST_TOKEN_GENERATE'],
        withTwoFactor: true,
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => Promise.reject(error));
}

export default loginService;
