import React from 'react';
import PropTypes from 'prop-types';

const HelpInfo = (props) => {
  const { label, linkLabel } = props;

  const handleClick = () => {
    if (window?.userengage) window.userengage('widget.open');
  };

  return (
    <div className="d-flex justify-content-center flex-column flex-md-row text-center">
      <span className="text text-body">{label}</span>
      <p
        className="ml-sm-1 font-weight-medium text text-color-primary"
        role="button"
        onClick={handleClick}
      >
        {linkLabel}
      </p>
    </div>
  );
};

HelpInfo.propTypes = {
  label: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
};

export default HelpInfo;
