import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const TimerButton = (props) => {
  const { cooldown, buttonLabel, buttonDisabled, onClick, awaitMessage, initTimestamp } = props;
  const [baseTimestamp, setBaseTimestamp] = useState(initTimestamp || Date.now());
  const [timePassed, setTimePassed] = useState(Date.now() - baseTimestamp);

  useEffect(() => {
    if (initTimestamp) {
      setBaseTimestamp(initTimestamp);
    }
  }, [initTimestamp]);

  const intervalId = useRef(null);
  useEffect(() => {
    intervalId.current = setInterval(() => {
      setTimePassed(Date.now() - baseTimestamp);
    }, 500);

    return function cleanup() {
      clearInterval(intervalId.current);
    };
  });

  const getHumanTime = () => {
    const timeLeft = cooldown - timePassed;
    const minutes = Math.floor(timeLeft / 1000 / 60);
    const seconds = Math.floor(timeLeft / 1000 - minutes * 60);
    const pad = (n) => {
      return n < 10 ? `0${n}` : n;
    };
    return `${pad(minutes)}:${pad(seconds)}`;
  };

  const handleClick = () => {
    if (!initTimestamp) {
      setBaseTimestamp(Date.now());
    }
    onClick();
  };

  const isButtonActive = timePassed > cooldown;

  const getContent = () => {
    return isButtonActive ? (
      <button
        className="text-body-small btn btn-link font-weight-semi-bold ml-sm-2 text-color-primary timer-button p-0"
        type="button"
        onClick={handleClick}
        disabled={buttonDisabled}
      >
        {buttonLabel}
      </button>
    ) : (
      <span className="font-weight-semi-bold ml-sm-2 text-color-primary text-body-small">{` ${awaitMessage} ${getHumanTime()}`}</span>
    );
  };

  return getContent();
};

TimerButton.propTypes = {
  cooldown: PropTypes.number,
  buttonLabel: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  awaitMessage: PropTypes.string,
  initTimestamp: PropTypes.number,
};

export default TimerButton;
