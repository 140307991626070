import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';

const LanguageOption = (props) => {
  const { label, lang, isActive } = props;
  const activeItem = (
    <Nav.Link eventKey={lang} title={label} className="nav-link-color active">
      {label}
    </Nav.Link>
  );
  const innActiveItem = (
    <Nav.Link eventKey={lang} title={label} className="nav-link-color">
      {label}
    </Nav.Link>
  );

  return isActive ? activeItem : innActiveItem;
};

LanguageOption.propTypes = {
  label: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

export default LanguageOption;
