import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import TransferForm from '../../components/TransferForm/TransferForm';
import { useTranslation } from 'react-i18next';

const TransferPage = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const pinCode = new URLSearchParams(search).get('pin');

  useEffect(() => {
    if (pinCode && token) {
      history.push(`/${i18n.language}/${t('urls.certificateInvitePage')}/${token}?pin=${pinCode}`);
    }
  }, [pinCode, token, history, i18n, t]);

  return (
    <>
      <Container className="d-flex flex-column h-100">
        <Row className="pl-3 pr-3">
          <Col>
            <Header noRedirect redirectType={'login'} />
          </Col>
        </Row>
        <Row className="p-3 flex-fill align-items-center">
          <Col>
            <TransferForm />
          </Col>
        </Row>

        <Row className="pl-3 pr-3">
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TransferPage;
