import maintenanceConstants from '../constants/maintenance.constants';
import maintenanceService from '../services/maintenance.service';

const maintenanceActions = { getMaintenanceStatus };

function getMaintenanceStatus() {
  return (dispatch) => {
    dispatch({ type: maintenanceConstants.MAINTENANCE_STATUS_REQUEST });
    maintenanceService
      .getMaintenanceStatus()
      .then(() => {
        dispatch({
          type: maintenanceConstants.MAINTENANCE_STATUS_SUCCESS,
          isMaintenance: false,
          message: '',
        });
      })
      .catch((error) => {
        let isMaintenance = false;
        let message = null;
        if (error.response.status === 503) {
          const errors = error.response?.data?.errors || [];

          errors.forEach((errorMessage) => {
            if (errorMessage?.code === 'UNDER_MAINTENANCE_ERROR') {
              isMaintenance = true;
              message = errorMessage.message;
            }
          });
        }
        if (isMaintenance) {
          dispatch({
            type: maintenanceConstants.MAINTENANCE_STATUS_SUCCESS,
            isMaintenance: true,
            message: message,
          });
        } else {
          dispatch({ type: maintenanceConstants.MAINTENANCE_STATUS_FAILURE });
        }
      });
  };
}

export default maintenanceActions;
