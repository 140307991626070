import filesModuleConstants from '../constants/filesModule.constants';
import { REQUEST_STATUS } from '../../models/common.model';

const initialState = {
  getFileStatus: REQUEST_STATUS.IDLE,
  fileUrl: null,
};

function filesModule(state = initialState, action) {
  switch (action.type) {
    case filesModuleConstants.GET_FILE_REQUEST:
      return {
        ...state,
        getFileStatus: REQUEST_STATUS.PENDING,
      };
    case filesModuleConstants.GET_FILE_SUCCESS:
      return {
        ...state,
        getFileStatus: REQUEST_STATUS.SUCCESS,
        fileUrl: action.fileUrl,
      };
    case filesModuleConstants.GET_FILE_FAILURE:
      return {
        ...state,
        getFileStatus: REQUEST_STATUS.ERROR,
      };
    default:
      return state;
  }
}

export default filesModule;
