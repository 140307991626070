import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import UserAvatarWithBadge from '../UserAvatarWithBadge/UserAvatarWithBadge';

const UserAvatarAndTextItem = ({
  className: classNameProp,
  size,
  text,
  additionalText,
  description,
  mainImageAlt,
  mainImageSrc,
  badgeChildren,
  badgeImageAlt,
  badgeImageSrc,
  tooltip,
  spacing,
}) => {
  return (
    <div className={clsx('user-avatar-with-text-wrapper d-flex', classNameProp)}>
      <UserAvatarWithBadge
        size={size}
        src={mainImageSrc}
        alt={mainImageAlt}
        badgeChildren={badgeChildren}
        badgeAlt={badgeImageAlt}
        badgeSrc={badgeImageSrc}
        tooltip={tooltip}
      >
        {text}
      </UserAvatarWithBadge>
      <div className={`user-avatar-text-wrapper ml-${spacing}`}>
        <div className="user-avatar-text-main">
          <h6 className="m-0">{additionalText}</h6>
        </div>
        <div className="user-avatar-text-secondary text-body-small text-color-light-darken text">
          {description}
        </div>
      </div>
    </div>
  );
};

UserAvatarAndTextItem.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
  text: PropTypes.string.isRequired,
  additionalText: PropTypes.string,
  description: PropTypes.string,
  mainImageAlt: PropTypes.string,
  mainImageSrc: PropTypes.string,
  badgeChildren: PropTypes.node,
  badgeImageAlt: PropTypes.string,
  badgeImageSrc: PropTypes.string,
  spacing: PropTypes.number,
  tooltip: PropTypes.node,
};

UserAvatarAndTextItem.defaultProps = {
  spacing: 3,
  mainImageAlt: '',
  badgeImageAlt: '',
};

export default UserAvatarAndTextItem;
