import twoFactorConstants from '../constants/twoFactor.constants';
import twoFactorService from '../services/twoFactor.service';

// eslint-disable-next-line import/prefer-default-export
export const twoFactorActions = {
  openTwoFactor,
  sendTwoFactor,
  closeTwoFactor,
  reSendTwoFactorCode,
  initTwoFactorSession,
};

function openTwoFactor(requestData, mode = 'initial', is2faError = '2FA_REQUIRED') {
  return { type: twoFactorConstants.TWO_FACTOR_INIT, requestData, mode, is2faError };
}

function initTwoFactorSession(permissions) {
  return (dispatch) => {
    dispatch({ type: twoFactorConstants.TWO_FACTOR_INIT_SESSION_REQUEST });
    twoFactorService
      .initTwoFactorSession(permissions)
      .then((response) =>
        dispatch({ type: twoFactorConstants.TWO_FACTOR_INIT_SESSION_SUCCESS, response })
      )
      .catch((error) =>
        dispatch({ type: twoFactorConstants.TWO_FACTOR_INIT_SESSION_FAILURE, error })
      );
  };
}

function sendTwoFactor(code, twoFactorId) {
  return (dispatch) => {
    dispatch({ type: twoFactorConstants.TWO_FACTOR_REQUEST });
    twoFactorService
      .sendTwoFactor(code, twoFactorId)
      .then((response) => {
        dispatch({ type: twoFactorConstants.TWO_FACTOR_SUCCESS, response });
      })
      .catch((error) => {
        dispatch({ type: twoFactorConstants.TWO_FACTOR_FAILURE, error });
      });
  };
}

function reSendTwoFactorCode(twoFactorId) {
  return (dispatch) => {
    dispatch({ type: twoFactorConstants.TWO_FACTOR_RESEND_REQUEST });
    twoFactorService
      .reSendTwoFactorCode(twoFactorId)
      .then(() => {
        dispatch({ type: twoFactorConstants.TWO_FACTOR_RESEND_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: twoFactorConstants.TWO_FACTOR_RESEND_FAILURE });
      });
  };
}

function closeTwoFactor() {
  return { type: twoFactorConstants.TWO_FACTOR_CLOSE };
}
