import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PasswordMeter = (props) => {
  const { value } = props;
  const { t } = useTranslation();
  /*
   * TODO: replace ratePassword when rating method is chosen
   * */
  const ratePassword = (pass) => {
    let score = 0;
    if (!pass) return score;

    // award every unique letter until 5 repetitions
    const letters = {};
    for (let i = 0; i < pass.length; i += 1) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const check in variations) {
      variationCount += variations[check] === true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    score = parseInt(score, 10);

    if (score > 80) return 3;
    if (score > 60) return 2;
    return 1;
  };

  const passwordRating = ratePassword(value);

  const getLabelClassByRating = () => {
    switch (passwordRating) {
      case 3:
        return 'strong';
      case 2:
        return 'medium';
      case 1:
        return 'easy';
      default:
        return 'none';
    }
  };

  const PasswordBar = ({ active, rating }) => {
    const barClasses = ['password-meter--bar', `bar-${rating}`];
    if (active) {
      barClasses.push('bar-active');
    }
    return <div className={clsx(barClasses)} />;
  };

  const strengthKey = getLabelClassByRating();

  return (
    <Row className="password-meter no-gutters">
      <Col xs={3} className="d-flex align-items-center pr-1">
        <PasswordBar active={passwordRating > 0} rating="easy" />
      </Col>
      <Col xs={3} className="d-flex align-items-center  pr-1">
        <PasswordBar active={passwordRating > 1} rating="medium" />
      </Col>
      <Col xs={3} className="d-flex align-items-center pr-1">
        <PasswordBar active={passwordRating > 2} rating="strong" />
      </Col>

      <Col
        xs={3}
        className={`text-body-small font-weight-medium d-flex align-items-center  justify-content-center password-meter-label ${strengthKey}`}
      >
        {t(`passwordMeter.label.${strengthKey}`)}
      </Col>
    </Row>
  );
};

PasswordMeter.propTypes = {
  value: PropTypes.string,
};

export default PasswordMeter;
