import alertsConstants from '../constants/alerts.constants';
import { REQUEST_STATUS } from '../../models/common.model';

function alerts(state = {}, action) {
  switch (action.type) {
    case alertsConstants.SHOW_SUCCESS_ALERT:
      return {
        type: REQUEST_STATUS.SUCCESS,
        message: action.message,
      };
    case alertsConstants.SHOW_INFO_ALERT:
      return {
        type: 'info',
        message: action.message,
      };
    case alertsConstants.SHOW_ERROR_ALERT:
      return {
        type: REQUEST_STATUS.ERROR,
        message: action.message,
      };
    case alertsConstants.CLEAR_ALERT:
      return {};
    default:
      return state;
  }
}

export default alerts;
