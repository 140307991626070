import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import links from '../../helpers/mainLinks';
import i18n from '../../helpers/i18n';

const LoginOrRegisterButtons = ({
  onLogin,
  onRegister,
  isLoggedIn,
  loggedLabel,
  onLoggedAction,
  withTerms,
}) => {
  const { t } = useTranslation();
  const { language } = i18n;
  const mainLinks = links(language);
  const [termsChecked, setTermsChecked] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onRegisterProxy = () => {
    setFormSubmitted(true);
    if (termsChecked || !withTerms) {
      onRegister();
    }
  };

  return (
    <div className="login-register-buttons-wrapper text-center w-100">
      {!isLoggedIn && (
        <>
          <h6 className="m-0 font-weight-semi-bold">{t('loginRegisterButtons.heading')}</h6>
          <Button className="w-100 mt-4 mt-sm-5 mb-2 mb-sm-5" onClick={onLogin}>
            {t('loginRegisterButtons.login')}
          </Button>
          <span className="text-color-light">{t('loginRegisterButtons.or')}</span>
          <Button
            className={`w-100 text-color-primary mt-2 mt-sm-5 ${withTerms && 'mb-3'}`}
            variant="light"
            onClick={onRegisterProxy}
          >
            {t('loginRegisterButtons.newAccount')}
          </Button>

          {withTerms && (
            <Form.Group controlId="formRegisterTerms">
              <Form.Check type="checkbox" className="no-select">
                <Form.Check.Input
                  isInvalid={!termsChecked && formSubmitted}
                  checked={termsChecked}
                  onChange={(event) => setTermsChecked(event.target.checked)}
                />
                <Form.Check.Label className="d-flex align-items-center text-left">
                  <span
                    className="ml-3"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: t('register.termsText', {
                        privacyPolicyUrl: mainLinks.privacyPolicy,
                        termsOfServiceUrl: mainLinks.termsOfService,
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          )}
        </>
      )}
      {isLoggedIn && (
        <Button className="w-100" onClick={onLoggedAction}>
          {loggedLabel}
        </Button>
      )}
    </div>
  );
};

LoginOrRegisterButtons.propTypes = {
  onLogin: PropTypes.func,
  onRegister: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  loggedLabel: PropTypes.string,
  onLoggedAction: PropTypes.func,
  withTerms: PropTypes.bool,
};

LoginOrRegisterButtons.defaultProps = {
  withTerms: true,
};

export default LoginOrRegisterButtons;
