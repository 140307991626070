import loginConstants from '../constants/login.constants';
import alertsActions from './alerts.actions';
import loginService from '../services/login.service';

const loginActions = {
  login,
  preLogin,
  clearStatus,
};

function login(token, extended = false) {
  return (dispatch) => {
    dispatch({ type: loginConstants.LOGIN_REQUEST });
    loginService
      .login(token, extended)
      .then((response) => {
        dispatch({ type: loginConstants.LOGIN_SUCCESS, response });
      })
      .catch((error) => {
        dispatch({ type: loginConstants.LOGIN_FAILURE, error });
        if (error.response.status === 403) {
          dispatch(alertsActions.error('Brak uprawnień'));
        } else {
          dispatch(
            alertsActions.error(
              'Podane dane są nieprawidłowe. Użytkownik nie został odnaleziony w naszej bazie lub hasło jest błędne.'
            )
          );
        }
      });
  };
}

function preLogin(email, password, captchaToken) {
  return (dispatch) => {
    dispatch({ type: loginConstants.PRE_LOGIN_REQUEST });
    loginService
      .preLogin(email, password, captchaToken)
      .then((response) => {
        dispatch({ type: loginConstants.PRE_LOGIN_SUCCESS, shortToken: response.data });
      })
      .catch((error) => {
        dispatch({ type: loginConstants.PRE_LOGIN_FAILURE, error });
      });
  };
}

function clearStatus() {
  return { type: loginConstants.RESET_STATUS };
}

export default loginActions;
