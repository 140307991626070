import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ConfirmationScreen from '../../components/ConfirmationScreen/ConfirmationScreen';
import maintenanceActions from '../../redux/actions/maintenance.actions';
import { REQUEST_STATUS } from '../../models/common.model';

const MaintenancePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const intervalRef = useRef(null);

  const { isUnderMaintenance, maintenanceStatus, isUnderMaintenanceMessage } = useSelector(
    (state) => state.maintenanceStore
  );
  const [firstChecked, setFirstChecked] = useState(false);

  const checkForMaintenance = () => {
    dispatch(maintenanceActions.getMaintenanceStatus());
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkForMaintenance();
    }, 5000);
    return () => {
      clearInterval(intervalRef.current);
    };
  });

  useEffect(() => {
    if (maintenanceStatus === REQUEST_STATUS.SUCCESS) {
      setFirstChecked(true);
    }
  }, [maintenanceStatus, setFirstChecked]);

  useEffect(() => {
    if (!isUnderMaintenance && firstChecked) {
      clearInterval(intervalRef.current);
      window.location.pathname = '/';
    }
  }, [isUnderMaintenance, intervalRef, firstChecked]);

  return (
    <>
      <Container className="d-flex flex-column h-100">
        <Row className="p-3 flex-fill align-items-center">
          <Col>
            <ConfirmationScreen
              imgSrc="/images/maintenance.svg"
              header={t('maintenancePage.header')}
              text={
                moment(parseInt(isUnderMaintenanceMessage)).isValid()
                  ? `${t('maintenancePage.descriptionWithDate')} ${moment(
                      parseInt(isUnderMaintenanceMessage)
                    ).format('YYYY.MM.DD HH:mm')}`
                  : t('maintenancePage.description')
              }
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

MaintenancePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default MaintenancePage;
