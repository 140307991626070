import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { REQUEST_STATUS } from '../../../models/common.model';

const CodeInput = forwardRef(
  ({ isAutofocus = true, codeLength, onValueChange, onAcceptValue, hasErrors }, ref) => {
    const defaultValue = Array(codeLength).join('.').split('.');
    const [codeValue, setCodeValue] = useState(defaultValue);
    const [isPasting, setIsPasting] = useState(false);
    const [currentFocusIndex, setCurrentFocusIndex] = useState(null);

    const inputs = [];
    const inputRefs = [];

    const isNumeric = (str) =>
      (typeof str === 'number' || typeof str === 'string') && !Number.isNaN(str - parseFloat(str));

    const clearCode = () => {
      const newValue = defaultValue;
      setCodeValue(newValue);

      if (typeof onValueChange === 'function') {
        onValueChange(newValue.join(''));
      }
    };

    useImperativeHandle(ref, () => ({
      clearCode,
    }));

    const onInputChange = (value, index) => {
      if (!isPasting) {
        if (value.length < 2) {
          const newValue = Array.from(codeValue);
          newValue[index] = value.replace(/[^0-9]/g, '');
          setCodeValue(newValue);

          if (typeof onValueChange === 'function') {
            onValueChange(newValue.join(''));
          }
        }
        if (value !== '') {
          if (index < codeLength - 1) {
            inputRefs[index + 1].current.focus();
          }
        } else if (index > 0) {
          inputRefs[index - 1].current.focus();
        }
      } else {
        inputRefs[index].current.value = '';
        inputRefs[value.length >= codeLength ? codeLength - 1 : value.length].current.focus();
        setIsPasting(false);

        if (typeof onValueChange === 'function') {
          onValueChange(codeValue.join(''));
        }
      }
    };

    const onInputFocus = (event, index) => {
      if (event.target.value.length > 0) {
        event.target.select();
      }
      setCurrentFocusIndex(index);
    };

    const onKeyUp = (event, index) => {
      if (event.keyCode === 13) {
        onAcceptValue(codeValue.join(''));
      }
      if (
        index > 0 &&
        ((event.keyCode === 8 &&
          (inputRefs[index].current.value === '' ||
            inputRefs[index].current.value === undefined)) ||
          event.keyCode === 37)
      ) {
        inputRefs[index - 1].current.focus();
      }
      if (index < codeLength - 1 && event.keyCode === 39) {
        inputRefs[index + 1].current.focus();
      }
    };

    const onPaste = (event) => {
      setIsPasting(true);
      const clipboardData = event.clipboardData.getData('text/plain').replace(/\s/g, '');
      const valueToSetFromClipboard = [];

      if (isNumeric(clipboardData)) {
        const splitClipboardData = clipboardData.split(/(?!$)/u);
        for (let i = 0; i < codeLength; i += 1) {
          valueToSetFromClipboard[i] = splitClipboardData[i] || '';
        }

        return setCodeValue(valueToSetFromClipboard);
      }
      return false;
    };

    const renderSingleInput = (index, inputRef) => {
      const isInputAutofocus = isAutofocus && index === 0;
      const focusClass = currentFocusIndex === index ? 'focused' : '';
      const errorClass = hasErrors ? REQUEST_STATUS.ERROR : '';
      const filledClass = codeValue[index] !== '' && 'input-filled';

      return (
        <div
          className={clsx(['code-input', focusClass, errorClass, filledClass])}
          key={`input-code-${index}`}
        >
          <span />
          <input
            ref={inputRef}
            value={codeValue[index]}
            maxLength={1}
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus={isInputAutofocus}
            onChange={() => {}}
            onInput={(e) => {
              if (isNumeric(e.target.value) || e.target.value === '')
                onInputChange(e.target.value, index);
            }}
            onFocus={(e) => {
              onInputFocus(e, index);
            }}
            onBlur={() => setCurrentFocusIndex(null)}
            onKeyUp={(e) => {
              onKeyUp(e, index);
            }}
            onPaste={(e) => {
              onPaste(e);
            }}
          />
        </div>
      );
    };

    for (let i = 0; i < codeLength; i += 1) {
      const newRef = React.createRef();
      inputs.push(renderSingleInput(i, newRef));
      inputRefs.push(newRef);
    }
    return (
      <div className="code-input-wrapper" ref={ref}>
        {inputs}
      </div>
    );
  }
);

CodeInput.propTypes = {
  codeLength: PropTypes.number,
  onValueChange: PropTypes.func,
  onAcceptValue: PropTypes.func,
  hasErrors: PropTypes.bool,
  isAutofocus: PropTypes.bool,
};

export default CodeInput;
