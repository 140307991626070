import axios from '../../helpers/axios';

export const documentModule = 'document-service';
export const documentDSLModule = 'document-dsl-service';

const documentService = { getDocumentInvitationDetails };

function getDocumentInvitationDetails(invitationId, service = documentModule) {
  return axios
    .get(`/${service}/invitations/${invitationId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export default documentService;
