import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import UserAvatar from '../UserAvatar/UserAvatar';

const UserAvatarWithBadge = (props) => {
  const {
    children,
    className: classNameProp,
    tooltip,
    color,
    customColor,
    size,
    alt,
    src,
    badgeChildren,
    badgeSize,
    badgeAlt,
    badgeSrc,
    badgeTooltip,
    badgeColor,
    badgeCustomColor,
    coefficientColor,
  } = props;

  const renderAvatar = () => {
    return (
      <div>
        <UserAvatar
          alt={alt}
          src={src}
          size={size}
          color={color}
          customColor={customColor}
          coefficientColor={coefficientColor}
        >
          {children}
        </UserAvatar>
        {(badgeChildren || badgeSrc) && (
          <UserAvatar
            size={badgeSize}
            alt={badgeAlt}
            src={badgeSrc}
            tooltip={badgeTooltip}
            color={badgeColor}
            customColor={badgeCustomColor}
            className="user-avatar-badge"
          >
            {badgeChildren}
          </UserAvatar>
        )}
      </div>
    );
  };

  const renderWithTooltip = () => {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-avatar-${props.key}`}>{tooltip}</Tooltip>}
      >
        {renderAvatar()}
      </OverlayTrigger>
    );
  };

  const renderContent = () => {
    return tooltip ? renderWithTooltip() : renderAvatar();
  };
  return (
    <div className={clsx('user-avatar-with-badge-wrapper', `${String(size)}Size`, classNameProp)}>
      {renderContent()}
    </div>
  );
};

UserAvatarWithBadge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
  color: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  customColor: PropTypes.string,
  coefficientColor: PropTypes.number,
  tooltip: PropTypes.string,
  badgeSize: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
  badgeChildren: PropTypes.node,
  badgeAlt: PropTypes.string,
  badgeSrc: PropTypes.string,
  badgeTooltip: PropTypes.string,
  badgeColor: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  badgeCustomColor: PropTypes.string,
};

UserAvatarWithBadge.defaultProps = {
  size: 'large',
  badgeSize: 'mini',
};

export default UserAvatarWithBadge;
