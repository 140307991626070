const loginConstants = {
  PRE_LOGIN_REQUEST: 'PRE_LOGIN_REQUEST',
  PRE_LOGIN_SUCCESS: 'PRE_LOGIN_SUCCESS',
  PRE_LOGIN_FAILURE: 'PRE_LOGIN_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  RESET_STATUS: 'RESET_STATUS',
};

export default loginConstants;
