const registerConstants = {
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  RE_SEND_ACTIVATION_EMAIL_REQUEST: 'RE_SEND_ACTIVATION_EMAIL_REQUEST',
  RE_SEND_ACTIVATION_EMAIL_SUCCESS: 'RE_SEND_ACTIVATION_EMAIL_SUCCESS',
  RE_SEND_ACTIVATION_EMAIL_FAILURE: 'RE_SEND_ACTIVATION_EMAIL_FAILURE',

  GET_ACTIVATION_CODE_STATUS_REQUEST: 'GET_ACTIVATION_CODE_STATUS_REQUEST',
  GET_ACTIVATION_CODE_STATUS_SUCCESS: 'GET_ACTIVATION_CODE_STATUS_SUCCESS',
  GET_ACTIVATION_CODE_STATUS_FAILURE: 'GET_ACTIVATION_CODE_STATUS_FAILURE',

  ADD_PHONE_NUMBER_REQUEST: 'ADD_PHONE_NUMBER_REQUEST',
  ADD_PHONE_NUMBER_SUCCESS: 'ADD_PHONE_NUMBER_SUCCESS',
  ADD_PHONE_NUMBER_FAILURE: 'ADD_PHONE_NUMBER_FAILURE',

  VERIFY_PHONE_NUMBER_REQUEST: 'VERIFY_PHONE_NUMBER_REQUEST',
  VERIFY_PHONE_NUMBER_SUCCESS: 'VERIFY_PHONE_NUMBER_SUCCESS',
  VERIFY_PHONE_NUMBER_FAILURE: 'VERIFY_PHONE_NUMBER_FAILURE',

  RE_SEND_CONFIRMATION_CODE_REQUEST: 'RE_SEND_CONFIRMATION_CODE_REQUEST',
  RE_SEND_CONFIRMATION_CODE_SUCCESS: 'RE_SEND_CONFIRMATION_CODE_SUCCESS',
  RE_SEND_CONFIRMATION_CODE_FAILURE: 'RE_SEND_CONFIRMATION_CODE_FAILURE',

  SET_PASSWORD_REQUEST: 'SET_PASSWORD_REQUEST',
  SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_FAILURE: 'SET_PASSWORD_FAILURE',

  RESET_REGISTER_STATUS: 'RESET_REGISTER_STATUS',
};

export default registerConstants;
