import ReactGA from 'react-ga4';

const gaHelper = { initialize, sendEvent };

const reactGaArgs = {
  trackingCode: process.env.REACT_APP_GA_ID,
  options: {
    gtagOptions: {
      testMode: process.env.NODE_ENV !== 'production',
      debug: false,
      send_page_view: false,
    },
  },
};

function initialize() {
  if (reactGaArgs?.trackingCode) {
    ReactGA.initialize(reactGaArgs.trackingCode, reactGaArgs.options);
  }
}

function sendEvent(name) {
  ReactGA.event({
    category: 'User',
    action: name,
  });
}

export default gaHelper;
