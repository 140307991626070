import React from 'react';
import PropTypes from 'prop-types';

const Logo = (props) => {
  const { src } = props;
  return (
    <div className="logo">
      <img src={src} alt="logo" />
    </div>
  );
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Logo;
