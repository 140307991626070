import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Row, Col, Button } from 'react-bootstrap';
import Logo from '../Logo/Logo';
import CustomLink from '../CustomLink/CutomLink';

const Header = ({ redirectType, noRedirect }) => {
  const { t } = useTranslation();
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });

  const redirect = (
    <div className="d-flex align-items-center justify-content-end">
      {redirectType === 'login' ? (
        <>
          <span className="mb-0">{t('header.loginRedirectText')}</span>
          <Button variant="link" className="ml-2 p-0 link">
            <CustomLink to="/">{t('header.loginRedirectLinkText')}</CustomLink>
          </Button>
        </>
      ) : (
        <>
          <span className="mb-0">{t('header.registerRedirectText')}</span>
          <Button variant="link" className="ml-2 p-0 link">
            <CustomLink to={`/${t('urls.register')}`}>
              {t('header.registerRedirectLinkText')}
            </CustomLink>
          </Button>
        </>
      )}
    </div>
  );

  return (
    <Row className="header justify-content-between align-items-center">
      <Col xs={12} md={6} className="p-3">
        <CustomLink to="/">
          <Logo src="/images/logo.svg" />
        </CustomLink>
      </Col>
      {isDesktopVersion && !noRedirect && (
        <Col xs={12} md={6} className="p-3 text-center text-md-right">
          {redirect}
        </Col>
      )}
    </Row>
  );
};

Header.propTypes = {
  redirectType: PropTypes.string.isRequired,
  noRedirect: PropTypes.bool,
};

Header.defaultProps = {
  noRedirect: false,
};

export default Header;
