import documentConstants from '../constants/document.constants';
import { REQUEST_STATUS } from '../../models/common.model';

const initialState = {
  documentInvitationDetails: null,
  getDocumentInvitationStatus: REQUEST_STATUS.IDLE,
};

function document(state = initialState, action) {
  switch (action.type) {
    case documentConstants.GET_DOCUMENT_INVITATION_DETAILS_REQUEST:
      return {
        ...state,
        getDocumentInvitationStatus: REQUEST_STATUS.PENDING,
      };
    case documentConstants.GET_DOCUMENT_INVITATION_DETAILS_SUCCESS:
      return {
        ...state,
        getDocumentInvitationStatus: REQUEST_STATUS.SUCCESS,
        documentInvitationDetails: action.details,
      };
    case documentConstants.GET_DOCUMENT_INVITATION_DETAILS_FAILURE:
      return {
        ...state,
        getDocumentInvitationStatus: REQUEST_STATUS.ERROR,
      };
    default:
      return state;
  }
}

export default document;
