import axios from '../../helpers/axios';

const maintenanceService = { getMaintenanceStatus };

function getMaintenanceStatus() {
  return axios
    .get('/status', { overrideMaintenanceRedirect: true })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export default maintenanceService;
