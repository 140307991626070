import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import LoginForm from '../../components/pages/Login/LoginForm';
import Footer from '../../components/Footer/Footer';
import loginActions from '../../redux/actions/login.actions';
import redirectHelper from '../../helpers/redirectHelper';
import { REQUEST_STATUS } from '../../models/common.model';

const LoginPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tokenStatus = useSelector((state) => state.auth.tokenStatus);
  useEffect(() => {
    document.title = t('titles.login');
    return () => {
      dispatch(loginActions.clearStatus());
    };
  }, [t, dispatch]);

  useEffect(() => {
    if (tokenStatus === REQUEST_STATUS.SUCCESS) {
      redirectHelper.redirect();
    }
  }, [tokenStatus]);

  return (
    <>
      <Container className="d-flex flex-column h-100">
        <Row className="pl-3 pr-3">
          <Col>
            <Header redirectType="register" />
          </Col>
        </Row>
        <Row className="p-3 flex-fill align-items-center">
          <Col>
            <LoginForm />
          </Col>
        </Row>
        <Row className="pl-3 pr-3">
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
