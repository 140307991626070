import twoFactorConstants from '../constants/twoFactor.constants';
import { REQUEST_STATUS } from '../../models/common.model';

const initialState = {
  twoFactorActive: false,
  requestData: null,
  twoFactorMethod: null,
  twoFactorToken: null,
  twoFactorInitTimestamp: null,
  modalMode: 'initial',

  twoFactorSendStatus: REQUEST_STATUS.IDLE,
  twoFactorReSendStatus: REQUEST_STATUS.IDLE,
  twoFactorInitStatus: REQUEST_STATUS.IDLE,
  twoFactorInitError: null,
};

// eslint-disable-next-line import/prefer-default-export
export function twoFactor(state = initialState, action) {
  switch (action.type) {
    case twoFactorConstants.TWO_FACTOR_INIT:
      return {
        ...state,
        twoFactorActive: true,
        requestData: action.requestData,
        modalMode: action.mode,
        twoFactorInitTimestamp: Date.now(),
        twoFactorSendStatus:
          action.is2faError === '2FA_CODE_ERROR' ? REQUEST_STATUS.ERROR : REQUEST_STATUS.IDLE,
      };
    case twoFactorConstants.TWO_FACTOR_REQUEST:
      return {
        ...state,
        twoFactorSendStatus: REQUEST_STATUS.PENDING,
      };
    case twoFactorConstants.TWO_FACTOR_SUCCESS:
      return {
        ...state,
        twoFactorSendStatus: REQUEST_STATUS.SUCCESS,
        twoFactorToken: action.response.data.data.twoFactorToken,
      };
    case twoFactorConstants.TWO_FACTOR_FAILURE:
      return {
        ...state,
        twoFactorSendStatus: REQUEST_STATUS.ERROR,
        twoFactorError: action.error,
      };
    case twoFactorConstants.TWO_FACTOR_RESEND_REQUEST:
      return {
        ...state,
        twoFactorReSendStatus: REQUEST_STATUS.PENDING,
      };
    case twoFactorConstants.TWO_FACTOR_RESEND_SUCCESS:
      return {
        ...state,
        twoFactorReSendStatus: REQUEST_STATUS.SUCCESS,
        twoFactorInitTimestamp: Date.now(),
      };
    case twoFactorConstants.TWO_FACTOR_RESEND_FAILURE:
      return {
        ...state,
        twoFactorReSendStatus: REQUEST_STATUS.ERROR,
      };
    case twoFactorConstants.TWO_FACTOR_CLOSE:
      return {
        ...initialState,
      };

    case twoFactorConstants.TWO_FACTOR_INIT_SESSION_REQUEST:
      return {
        ...state,
        twoFactorInitStatus: REQUEST_STATUS.PENDING,
        twoFactorInitError: null,
      };

    case twoFactorConstants.TWO_FACTOR_INIT_SESSION_SUCCESS:
      return {
        ...state,
        twoFactorToken: action.response.data,
        twoFactorMethod: action.response.data.type,
        twoFactorInitStatus: REQUEST_STATUS.SUCCESS,
        twoFactorInitTimestamp: Date.now(),
        twoFactorInitError: null,
      };
    case twoFactorConstants.TWO_FACTOR_INIT_SESSION_FAILURE:
      return {
        ...state,
        twoFactorInitStatus: REQUEST_STATUS.ERROR,
        twoFactorInitError: action.error,
      };
    default:
      return state;
  }
}
