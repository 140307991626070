import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { lighten } from '../../helpers/colorManipulator';

const UserAvatar = (props) => {
  const {
    classes,
    className: classNameProp,
    size,
    customColor,
    alt,
    children,
    coefficientColor,
    tooltip,
    ...other
  } = props;

  const colors = [
    '#e53935',
    '#d81b60',
    '#8e24aa',
    '#5e35b1',
    '#3949ab',
    '#1e88e5',
    '#039be5',
    '#00acc1',
    '#00897b',
    '#43a047',
    '#7cb342',
    '#c0ca33',
    '#fdd835',
    '#ffb300',
    '#fb8c00',
    '#f4511e',
  ];

  const isChildrenString = !!(typeof children === 'string' || children instanceof String);
  const returnChildren = () => {
    if (isChildrenString) {
      const splitText = children.split(' ');
      return `${splitText[0].charAt(0).toUpperCase()}${
        splitText?.length > 1 ? splitText[1].charAt(0).toUpperCase() : ''
      }`;
    }
    return children;
  };
  const child = returnChildren();

  const colorFromText = (text) => {
    const charCodes = String(text)
      .split('')
      .map((char) => char.charCodeAt(0))
      .join('');
    return parseInt(charCodes, 10);
  };

  const renderAvatar = () => (
    <div
      className={clsx('user-avatar', `${String(size)}Size`, classNameProp)}
      {...other}
      style={
        children
          ? {
              color: customColor || colors[colorFromText(child) % colors.length],
              backgroundColor: customColor
                ? lighten(customColor, coefficientColor)
                : lighten(colors[colorFromText(child) % colors.length], coefficientColor),
            }
          : null
      }
    >
      {child}
    </div>
  );

  const renderWithTooltip = () => {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-avatar-${props.key}`}>{tooltip}</Tooltip>}
      >
        {renderAvatar()}
      </OverlayTrigger>
    );
  };
  return <> {tooltip ? renderWithTooltip() : renderAvatar()} </>;
};

UserAvatar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['extraLarge', 'large', 'medium', 'small', 'mini']),
  customColor: PropTypes.string,
  coefficientColor: PropTypes.number,
  tooltip: PropTypes.string,
};
UserAvatar.defaultProps = {
  size: 'large',
  coefficientColor: 0.825,
};
export default UserAvatar;
