import React, { useCallback, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import TimerButton from '../TimerButton/TimerButton';

const ConfirmationScreen = ({
  imgSrc,
  header,
  text,
  buttonLink,
  buttonLabel,
  buttonOnClick,
  email,
  resendEmail,
  reSendPending,
  isStaticLink,
  staticLinkTarget,
}) => {
  const isDesktopVersion = useMediaQuery({ minWidth: 768 });
  const { i18n } = useTranslation();
  const history = useHistory();
  const { t } = useTranslation();

  const redirect = (toApp) => {
    if (buttonLink && !isStaticLink) {
      history.push(`/${i18n.language}/${buttonLink}`);
    } else if (buttonOnClick) {
      buttonOnClick(toApp);
    }
  };

  const getButton = () => {
    return (
      <Button variant="primary" className="w-100" onClick={redirect}>
        {buttonLabel}
      </Button>
    );
  };

  const getWrappedButton = () => {
    if (isStaticLink) {
      return (
        <a href={buttonLink} target={staticLinkTarget}>
          {getButton()}
        </a>
      );
    }
    return getButton();
  };
  const handleUserKeyPress = useCallback(({ keyCode }) => {
    if (keyCode === 13) {
      redirect(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
  return (
    <>
      <Row className="text-center justify-content-center">
        <Col xs={8} md={6} lg={4} className="mb-8">
          <img src={imgSrc} alt="" />
        </Col>
      </Row>
      <Row className="text-center">
        <Col xs={12} lg={{ span: 6, offset: 3 }}>
          <h1 className={clsx(!isDesktopVersion && 'h3')}>{header}</h1>
          {text && <h5 className="mb-0 text-color-light">{text}</h5>}
          {!email ? (
            <>
              {buttonLabel && (buttonLink || buttonOnClick) && (
                <Col xs={12} className="mt-7">
                  {getWrappedButton()}
                </Col>
              )}
            </>
          ) : (
            <>
              <span className="link link-extra-large">{email}</span>
              <div className="mt-6 text text-body text-body-small d-flex justify-content-center align-items-center flex-column flex-md-row">
                <span>{t('registerConfirmationScreen.question')}</span>
                <TimerButton
                  onClick={resendEmail}
                  buttonDisabled={reSendPending}
                  awaitMessage={t('twoFactor.sms.retryTimerText')}
                  buttonLabel={t('twoFactor.email.retryButton')}
                  cooldown={30000}
                />
                {/* <Button
                  variant="link"
                  className="pl-2 pt-0 pb-0 link link-underline"
                  onClick={resendEmail}
                  disabled={reSendPending}
                >
                  {t('registerConfirmationScreen.button')}
                </Button> */}
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

ConfirmationScreen.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  email: PropTypes.string,
  resendEmail: PropTypes.func,
  reSendPending: PropTypes.bool,
  isStaticLink: PropTypes.bool,
  staticLinkTarget: PropTypes.oneOf(['_blank']),
};

ConfirmationScreen.defaultProps = {
  isStaticLink: false,
  staticLinkTarget: '_blank',
};

export default ConfirmationScreen;
