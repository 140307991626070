import passwordResetConstants from '../constants/passwordReset.constants';
import passwordResetService from '../services/passwordReset.service';

const passwordResetActions = {
  remindPassword,
  resetPassword,
  resetStore,
  checkResetToken,
};

function remindPassword(email, captchaToken) {
  return (dispatch) => {
    /*
     * Captcha - token
     * */
    dispatch({ type: passwordResetConstants.REMIND_PASSWORD_REQUEST });
    passwordResetService
      .initTwoFactorSession(email, captchaToken)
      .then(() => {
        dispatch({ type: passwordResetConstants.REMIND_PASSWORD_SUCCESS, email });
      })
      .catch((error) => {
        dispatch({ type: passwordResetConstants.REMIND_PASSWORD_FAILURE, error });
      });
  };
}

function resetPassword(resetToken, password) {
  return (dispatch) => {
    dispatch({ type: passwordResetConstants.RESET_PASSWORD_REQUEST });
    passwordResetService
      .setNewPassword(resetToken, password)
      .then(() => {
        dispatch({ type: passwordResetConstants.RESET_PASSWORD_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: passwordResetConstants.RESET_PASSWORD_FAILURE, error });
      });
  };
}

function checkResetToken(resetToken) {
  return (dispatch) => {
    dispatch({ type: passwordResetConstants.CHECK_RESET_PASSWORD_TOKEN_REQUEST });
    passwordResetService
      .getTwoFactorSessionStatus(resetToken)
      .then((response) => {
        dispatch({
          type: passwordResetConstants.CHECK_RESET_PASSWORD_TOKEN_SUCCESS,
          token: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: passwordResetConstants.CHECK_RESET_PASSWORD_TOKEN_FAILURE });
      });
  };
}

function resetStore() {
  return { type: passwordResetConstants.RESET_STORE };
}

export default passwordResetActions;
