import filesModuleConstants from '../constants/filesModule.constants';
import filesModuleService from '../services/filesModule.service';

const filesModuleActions = { getFile };

function getFile(token) {
  return (dispatch) => {
    dispatch({ type: filesModuleConstants.GET_FILE_REQUEST });
    filesModuleService
      .getFile(token)
      .then((response) => {
        const file = URL.createObjectURL(response);
        dispatch({ type: filesModuleConstants.GET_FILE_SUCCESS, fileUrl: file });
      })
      .catch((e) => {
        dispatch({ type: filesModuleConstants.GET_FILE_FAILURE, error: e });
      });
  };
}

export default filesModuleActions;
