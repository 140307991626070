const authConstants = {
  GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_ERROR: 'GET_USER_PROFILE_ERROR',

  CHECK_TOKEN_STATUS_REQUEST: 'CHECK_TOKEN_STATUS_REQUEST',
  CHECK_TOKEN_STATUS_SUCCESS: 'CHECK_TOKEN_STATUS_SUCCESS',
  CHECK_TOKEN_STATUS_FAILURE: 'CHECK_TOKEN_STATUS_FAILURE',

  DELETE_TOKEN_USER_REQUEST: 'DELETE_TOKEN_USER_REQUEST',
  DELETE_TOKEN_USER_SUCCESS: 'DELETE_TOKEN_USER_SUCCESS',
  DELETE_TOKEN_USER_FAILURE: 'DELETE_TOKEN_USER_FAILURE',
};

export default authConstants;
