import documentConstants from '../constants/document.constants';
import documentService from '../services/document.service';

const documentActions = { getDocumentInvitationDetails };

function getDocumentInvitationDetails(invitationId, service) {
  return (dispatch) => {
    dispatch({ type: documentConstants.GET_DOCUMENT_INVITATION_DETAILS_REQUEST });
    documentService
      .getDocumentInvitationDetails(invitationId, service)
      .then((response) => {
        dispatch({
          type: documentConstants.GET_DOCUMENT_INVITATION_DETAILS_SUCCESS,
          details: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: documentConstants.GET_DOCUMENT_INVITATION_DETAILS_FAILURE });
      });
  };
}

export default documentActions;
