import authConstants from '../constants/auth.constants';
import { REQUEST_STATUS } from '../../models/common.model';

const initialState = {
  tokenStatus: REQUEST_STATUS.IDLE,
  userProfile: null,
  userProfileRequestStatus: REQUEST_STATUS.IDLE,
  deleteTokenRequestStatus: REQUEST_STATUS.IDLE,
};
function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        userProfileRequestStatus: REQUEST_STATUS.PENDING,
      };
    case authConstants.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileRequestStatus: REQUEST_STATUS.SUCCESS,
        userProfile: action.response.data,
      };
    case authConstants.GET_USER_PROFILE_ERROR:
      return {
        ...state,
        userProfileRequestStatus: REQUEST_STATUS.ERROR,
      };
    case authConstants.CHECK_TOKEN_STATUS_REQUEST:
      return {
        ...state,
        tokenStatus: REQUEST_STATUS.PENDING,
      };
    case authConstants.CHECK_TOKEN_STATUS_SUCCESS:
      return {
        ...state,
        tokenStatus: REQUEST_STATUS.SUCCESS,
      };
    case authConstants.CHECK_TOKEN_STATUS_FAILURE:
      return {
        ...state,
        tokenStatus: REQUEST_STATUS.ERROR,
      };
    case authConstants.DELETE_TOKEN_USER_REQUEST:
      return {
        ...state,
        deleteTokenRequestStatus: REQUEST_STATUS.PENDING,
      };
    case authConstants.DELETE_TOKEN_USER_SUCCESS:
      return {
        ...state,
        deleteTokenRequestStatus: REQUEST_STATUS.SUCCESS,
      };
    case authConstants.DELETE_TOKEN_USER_FAILURE:
      return {
        ...state,
        deleteTokenRequestStatus: REQUEST_STATUS.ERROR,
      };
    default:
      return state;
  }
}

export default auth;
