/* eslint-disable no-console */
import React, { useState } from 'react';
import { Row, Col, Button, Form, Alert, InputGroup, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import CustomLink from '../../CustomLink/CutomLink';
import axios from '../../../helpers/axios';
import PasswordMeter from '../../PasswordInput/subcomponents/PasswordMeter/PasswordMeter';

const DemoComponent = () => {
  const testApiCall = (email, language) => {
    axios
      .post('/registration', { email, language })
      .then((response) => {
        console.log('SUCCESS ON INIT REGISTER ', response);
      })
      .catch((error) => console.error('ERROR ON INIT REGISTER ', error));
  };

  const testRegistrationCodesCall = (code) => {
    axios
      .get(`registration/codes/${code}`)
      .then((r) => console.log('success on get register status', r))
      .catch((e) => console.error('error on get register status', e));
  };

  const testPushNumberToCode = (code, number, prefix) => {
    axios
      .post(`registration/codes/${code}/phone`, {
        phoneNumber: {
          prefix,
          number,
        },
      })
      .then((r) => {
        console.log('SUCCESS ON INIT PHONE CONF ', r);
      })
      .catch((e) => {
        console.error('ERROR ON INIT PHONE CONF ', e);
      });
  };

  const testSetPassword = (code, password) => {
    axios
      .post(`registration/codes/${code}/password`, { password })
      .then((r) => {
        console.log('SUCCESS ON SET PASS ', r);
      })
      .catch((e) => {
        console.error('ERROR ON SET PASS ', e);
      });
  };

  const testConfirmCode = (code, confirmCode) => {
    axios
      .post(`registration/codes/${code}/phone/confirm/`, { confirmationCode: confirmCode })
      .then((r) => {
        console.log('SUCCESS ON CONF CODE ', r);
      })
      .catch((e) => {
        console.error('ERROR ON CONF CODE ', e);
      });
  };

  console.log('testingCalls', {
    initReg: testApiCall,
    regGetStatus: testRegistrationCodesCall,
    initPhoneConf: testPushNumberToCode,
    confPhone: testConfirmCode,
    setPassword: testSetPassword,
  });

  /* ******************************************************* */

  const { t } = useTranslation();
  const [passwordInputType, setPasswordInputType] = useState('password');

  const togglePasswordVisibility = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text');
    } else {
      setPasswordInputType('password');
    }
  };

  return (
    <>
      <Row className="login-form flex-column full-height">
        <Col>
          <div>
            <h1>Heading h1</h1>
            <h1>Heading h2</h1>
            <h3>Heading h3</h3>
            <h4>Heading h4</h4>
            <p className="text">
              {`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
          Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.`}
            </p>
            <Button className="semi-bold mb-2" variant="master">
              Submit
            </Button>
            <Button className="semi-bold mb-2" variant="primary" onClick={testApiCall}>
              Submit
            </Button>
            <Button className="semi-bold mb-2" variant="secondary">
              Submit
            </Button>
            <Button disabled className="semi-bold mb-2" variant="primary">
              Submit
            </Button>
            <Button disabled className="semi-bold mb-2" variant="secondary">
              Submit 2
            </Button>
            <div>
              <CustomLink className="font-weight-bold" to={`/${t('urls.passwordReset')}`}>
                <Button variant="link" className="light">
                  Forgot password?
                </Button>
              </CustomLink>
            </div>
            <div>
              <CustomLink className="font-weight-bold" to={`/${t('urls.passwordReset')}`}>
                <Button variant="link" className="dark">
                  Problem with login?
                </Button>
              </CustomLink>
            </div>
          </div>
          <div>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Text className="text-color-error font-weight-regular text-smaller">
                  We&apos;ll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Check me out" />
              </Form.Group>
              <Form.Group>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    type={passwordInputType}
                  />
                  <div className="input-suffix">
                    <Button
                      className={clsx(passwordInputType === 'text' && 'text-color-primary')}
                      variant="text"
                      onClick={togglePasswordVisibility}
                    >
                      <i className="fico fico-doxy-visibility" />
                    </Button>
                  </div>
                </InputGroup>
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
          <div className="mt-2">
            {['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].map(
              (variant, index) => (
                <Alert key={index} variant={variant}>
                  This is a {variant} alert—check it out!
                </Alert>
              )
            )}
          </div>
          <div>
            <PasswordMeter value="" />
            <PasswordMeter value="a" />
            <PasswordMeter value="Aa1Abashgjshd" />
            <PasswordMeter value="A14.aft2$afy26adkjhn" />
          </div>
        </Col>
      </Row>
    </>
  );
};

DemoComponent.propTypes = {};

export default DemoComponent;
