import loginConstants from '../constants/login.constants';
import { REQUEST_STATUS } from '../../models/common.model';

const initialState = {
  loginStatus: REQUEST_STATUS.IDLE,
  preLoginStatus: REQUEST_STATUS.IDLE,
  shortToken: null,
  errors: [],
};

function login(state = initialState, action) {
  switch (action.type) {
    case loginConstants.PRE_LOGIN_REQUEST:
      return {
        ...state,
        preLoginStatus: REQUEST_STATUS.PENDING,
      };
    case loginConstants.PRE_LOGIN_SUCCESS:
      return {
        ...state,
        preLoginStatus: REQUEST_STATUS.SUCCESS,
        shortToken: action.shortToken,
      };
    case loginConstants.PRE_LOGIN_FAILURE:
      return {
        ...state,
        preLoginStatus: REQUEST_STATUS.ERROR,
        errors: action?.error?.response?.data?.errors,
      };
    case loginConstants.LOGIN_REQUEST:
      return {
        ...state,
        loginStatus: REQUEST_STATUS.PENDING,
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.response.data,
        loginStatus: REQUEST_STATUS.SUCCESS,
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginStatus: REQUEST_STATUS.ERROR,
      };
    case loginConstants.RESET_STATUS:
      return initialState;
    default:
      return state;
  }
}

export default login;
