import axios from '../../helpers/axios';

const passwordResetService = {
  initTwoFactorSession,
  getTwoFactorSessionStatus,
  setNewPassword,
};

function initTwoFactorSession(email, captchaToken) {
  return axios
    .post(
      '/auth/security/password/reset',
      { email },
      {
        headers: {
          Captcha: captchaToken,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function getTwoFactorSessionStatus(resetToken) {
  return axios
    .get(`/auth/security/password/reset/codes/${resetToken}`, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function setNewPassword(resetToken, password) {
  return axios
    .post(
      `/auth/security/password/reset/codes/${resetToken}`,
      { password },
      {
        withTwoFactor: true,
        withPermissions: ['AUTH_USER_POST_PASSWORD'],
        withCredentials: true,
      }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export default passwordResetService;
