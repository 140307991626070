import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Logo from '../Logo/Logo';

const FullPageModal = ({ isOpen, children, showLogo, showClose, onClose }) => {
  return (
    <>
      {isOpen && (
        <div className="full-page-modal">
          <div className="full-page-modal-wrapper">
            <Container className="d-flex justify-content-center flex-column">
              {showLogo && (
                <Row className="full-page-modal-header justify-content-between align-items-center">
                  <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                    <div
                      onClick={() => {
                        window.location.href = process.env.REACT_APP_AUTH_FRONT_URL;
                      }}
                    >
                      <Logo src="/images/logo.svg" />
                    </div>

                    {showClose && (
                      <Button className="m-0 p-0" variant="text" onClick={onClose}>
                        <i className="fico-doxy-close" />
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
              <Row className="align-items-center full-page-modal-content no-gutters flex-grow-1 flex-md-grow-0">
                <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                  {children}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

FullPageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showLogo: PropTypes.bool,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
};

FullPageModal.defaultProps = {
  showLogo: true,
  showClose: false,
};

export default FullPageModal;
