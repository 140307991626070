import maintenanceConstants from '../constants/maintenance.constants';
import { REQUEST_STATUS } from '../../models/common.model';

const initialState = {
  maintenanceStatus: REQUEST_STATUS.IDLE,
  isUnderMaintenance: false,
  isUnderMaintenanceMessage: null,
};

function maintenanceStore(state = initialState, action) {
  switch (action.type) {
    case maintenanceConstants.MAINTENANCE_STATUS_REQUEST:
      return {
        ...state,
        maintenanceStatus: REQUEST_STATUS.PENDING,
      };
    case maintenanceConstants.MAINTENANCE_STATUS_SUCCESS:
      return {
        ...state,
        maintenanceStatus: REQUEST_STATUS.SUCCESS,
        isUnderMaintenance: action.isMaintenance,
        isUnderMaintenanceMessage: action.message,
      };
    case maintenanceConstants.MAINTENANCE_STATUS_FAILURE:
      return {
        ...state,
        maintenanceStatus: REQUEST_STATUS.ERROR,
      };
    default:
      return state;
  }
}

export default maintenanceStore;
