import LoginPage from '../pages/Login/LoginPage';
import RegisterPage from '../pages/Register/RegisterPage';
import PasswordLostPage from '../pages/PasswordReset/PasswordLostPage';
import DemoPage from '../pages/DemoPage/DemoPage';
import RegisterActivationPage from '../pages/Register/RegisterActivationPage';
import PasswordRestartPage from '../pages/PasswordReset/PasswordRestartPage';
import JoinWorkspacePage from '../pages/JoinWorkspace/JoinWorkspacePage';
import DocumentInvitePage from '../pages/DocumentInvite/DocumentInvitePage';
import MaintenancePage from '../pages/Maintenance/MaintenancePage';
import AccountDeleted from '../pages/AccountDeleted/AccountDeleted';
import TransferPage from '../pages/TransferPage/TransferPage';

const routes = [
  {
    id: 'login',
    type: 'route',
    component: LoginPage,
    exact: true,
    title: 'titles.login',
    locales: {
      pl: {
        path: '/',
      },
      en: {
        path: '/',
      },
    },
  },

  {
    id: 'register-with-email',
    type: 'route',
    component: RegisterPage,
    title: 'titles.register',
    exact: true,
    locales: {
      pl: {
        path: '/rejestracja/:inviteId/:email',
      },
      en: {
        path: '/registration/:inviteId/:email',
      },
    },
  },
  {
    id: 'register',
    type: 'route',
    component: RegisterPage,
    title: 'titles.register',
    locales: {
      pl: {
        path: '/rejestracja',
      },
      en: {
        path: '/registration',
      },
    },
  },
  {
    id: 'activate-account',
    type: 'route',
    component: RegisterActivationPage,
    title: 'titles.register',
    locales: {
      pl: {
        path: '/aktywacja-konta/:code',
      },
      en: {
        path: '/account-activation/:code',
      },
    },
  },
  {
    id: 'password-restart',
    type: 'route',
    component: PasswordRestartPage,
    locales: {
      pl: {
        path: '/reset-hasla/:code',
      },
      en: {
        path: '/password-reset/:code',
      },
    },
  },
  {
    id: 'password-lost',
    type: 'route',
    component: PasswordLostPage,
    locales: {
      pl: {
        path: '/przypomnienie-hasla',
      },
      en: {
        path: '/password-remind',
      },
    },
  },
  {
    id: 'demo',
    type: 'route',
    component: DemoPage,
    locales: {
      pl: {
        path: '/demo',
      },
      en: {
        path: '/demo',
      },
    },
  },
  {
    id: 'join-organisation',
    type: 'route',
    component: JoinWorkspacePage,
    locales: {
      pl: {
        path: '/workspace/dolacz/:inviteId',
      },
      en: {
        path: '/workspace/join/:inviteId',
      },
    },
  },
  {
    id: 'document-invite',
    type: 'route',
    component: DocumentInvitePage,
    locales: {
      pl: {
        path: '/dokument/dolacz/:inviteId',
      },
      en: {
        path: '/document/join/:inviteId',
      },
    },
  },
  {
    id: 'certificate-invite',
    type: 'route',
    component: DocumentInvitePage,
    locales: {
      pl: {
        path: '/certyfikat/dolacz/:inviteId',
      },
      en: {
        path: '/certificate/join/:inviteId',
      },
    },
  },
  {
    id: 'transfer',
    type: 'route',
    component: TransferPage,
    omitLang: true,
    locales: {
      pl: {
        path: '/transfer',
      },
      en: {
        path: '/transfer',
      },
    },
  },
  {
    id: 'maintenance',
    type: 'route',
    component: MaintenancePage,
    locales: {
      pl: {
        path: '/przerwa-techniczna',
      },
      en: {
        path: '/maintenance',
      },
    },
  },
  {
    id: 'accountDeleted',
    type: 'route',
    component: AccountDeleted,
    locales: {
      pl: {
        path: '/konto-usuniete',
      },
      en: {
        path: '/account-deleted',
      },
    },
  },
];

export default routes;
