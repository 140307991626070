import axios from '../../helpers/axios';

const filesModuleService = { getFile };

function getFile(token) {
  return axios
    .get(`/files-module/v1/file?token=${token}`, { responseType: 'blob' })
    .then((response) => response.data)
    .catch((e) => Promise.reject(e));
}

export default filesModuleService;
