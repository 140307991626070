const twoFactorConstants = {
  TWO_FACTOR_INIT: 'TWO_FACTOR_INIT',
  TWO_FACTOR_CLOSE: 'TWO_FACTOR_CLOSE',

  TWO_FACTOR_INIT_SESSION_REQUEST: 'TWO_FACTOR_INIT_SESSION_REQUEST',
  TWO_FACTOR_INIT_SESSION_SUCCESS: 'TWO_FACTOR_INIT_SESSION_SUCCESS',
  TWO_FACTOR_INIT_SESSION_FAILURE: 'TWO_FACTOR_INIT_SESSION_FAILURE',

  TWO_FACTOR_REQUEST: 'TWO_FACTOR_REQUEST',
  TWO_FACTOR_SUCCESS: 'TWO_FACTOR_SUCCESS',
  TWO_FACTOR_FAILURE: 'TWO_FACTOR_FAILURE',

  TWO_FACTOR_RESEND_REQUEST: 'TWO_FACTOR_RESEND_REQUEST',
  TWO_FACTOR_RESEND_SUCCESS: 'TWO_FACTOR_RESEND_SUCCESS',
  TWO_FACTOR_RESEND_FAILURE: 'TWO_FACTOR_RESEND_FAILURE',
};

export default twoFactorConstants;
