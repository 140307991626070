import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import DemoComponent from '../../components/pages/DemoComponent.jsx/DemoComponent';

const DemoPage = () => {
  return (
    <>
      <Container className="d-flex flex-column h-100">
        <Row className="p-3">
          <Col>
            <Header redirectType="register" />
          </Col>
        </Row>
        <Row className="p-3 flex-fill align-items-center">
          <Col>
            <DemoComponent />
          </Col>
        </Row>
        <Row className="p-3">
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DemoPage;
